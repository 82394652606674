import { appRouterPaths } from "../../../services/navigation/urls";
import { ComparasionPage } from "../../../pages/ComparisonPage";
import { AppRouteProps } from "../types";

export const comparisonPageRoute: AppRouteProps = {
  path: appRouterPaths.comparasion,
  element: <ComparasionPage />,
  link: "/llm_compare",
  name: "Сравнение LLM моделей",
};
