import type { AppIconProps } from "../IconTypes";

export const ChartIcon: React.FC<AppIconProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      className={className}
      width="100"
      height="59"
      viewBox="0 0 100 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2" height="59" rx="1" fill="#1A1A1A" />
      <path
        d="M20 55C20 54.4477 20.4477 54 21 54V54C21.5523 54 22 54.4477 22 55V57H20V55Z"
        fill="#4D4D4D"
      />
      <path
        d="M40 55C40 54.4477 40.4477 54 41 54V54C41.5523 54 42 54.4477 42 55V57H40V55Z"
        fill="#4D4D4D"
      />
      <path
        d="M60 55C60 54.4477 60.4477 54 61 54V54C61.5523 54 62 54.4477 62 55V57H60V55Z"
        fill="#4D4D4D"
      />
      <path
        d="M4 17C4.55228 17 5 17.4477 5 18V18C5 18.5523 4.55228 19 4 19L2 19L2 17L4 17Z"
        fill="#4D4D4D"
      />
      <path
        d="M4 37C4.55228 37 5 37.4477 5 38V38C5 38.5523 4.55228 39 4 39L2 39L2 37L4 37Z"
        fill="#4D4D4D"
      />
      <path
        d="M80 55C80 54.4477 80.4477 54 81 54V54C81.5523 54 82 54.4477 82 55V57H80V55Z"
        fill="#4D4D4D"
      />
      <rect
        x="100"
        y="57"
        width="2"
        height="100"
        rx="1"
        transform="rotate(90 100 57)"
        fill="#1A1A1A"
      />
      <circle cx="64" cy="12" r="3" fill="#ED0E0E" />
      <circle cx="25.5" cy="39.5" r="3.5" fill="#36D000" />
      <circle cx="43.5" cy="23.5" r="2.5" fill="#00D0AB" />
      <circle cx="91.5" cy="8.5" r="2.5" fill="#B300D0" />
      <circle cx="70.5" cy="35.5" r="3.5" fill="#1100D0" />
      <circle cx="19" cy="9" r="4" fill="#D05700" />
    </svg>
  );
};
