import type { AppIconProps } from "../IconTypes";

export const ArrowIcon: React.FC<AppIconProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width || "16"}
      height={height || "16"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6L8 10L12 6"
        stroke="#031B12"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
