import classNames from "classnames";
import styles from "./styles.module.css";
import { Dialog, DialogProps } from "@mui/material";

type Props = DialogProps & {
  className?: string;
};

export const AppDialog: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Dialog className={classNames(styles.container, className)} {...props} />
  );
};
