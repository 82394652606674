import { LocalAssistantInfo, ThreadInfo } from "../../../pages/AssistantPage";
import {
  getAssistants,
  createThread,
  getAssistantFunctionSchema,
} from "../../../services/api/methods";
import {
  AssistantFunctionNames,
  AssistantPostInfo,
  AssistantToolResources,
  AssistantToolsObject,
} from "../../../services/api/methodsTypes";

export const sortAssistantData = (assistantList: LocalAssistantInfo[]) => {
  const sortedAssistants = assistantList
    .filter((assistant) => assistant.metadata)
    .sort((a, b) => a?.name?.localeCompare(b.name));

  return sortedAssistants;
};

export const updateAssistantList = (): Promise<LocalAssistantInfo[]> => {
  return new Promise<LocalAssistantInfo[]>((resolve) => {
    getAssistants()
      .then((res) => {
        const sortedData = sortAssistantData(res.data);
        resolve(sortedData);
      })
      .catch(console.log);
  });
};

export const createThreadLink = (assistantId: string) => {
  return new Promise<ThreadInfo>((resolve) => {
    createThread()
      .then((res) => {
        const newThread = {
          assistantId: assistantId,
          threadId: res.id,
          messages: [],
        };

        resolve(newThread);
      })
      .catch(console.log);
  });
};

export const addThreadToThreadList = (
  threadList: ThreadInfo[],
  assistantId: string
) => {
  return new Promise<ThreadInfo[]>((resolve) => {
    const newThreadList = [...threadList];

    createThread().then((res) => {
      newThreadList.find(
        (thread) => thread.assistantId === assistantId
      )!.threadId = res.id;
      resolve(newThreadList);
    });
  });
};

export const createInitialThreadList = (
  assistantList: LocalAssistantInfo[]
) => {
  const newThreadList: ThreadInfo[] = [];

  for (const assistant of assistantList) {
    if (!assistant.id) continue;
    const newThread = {
      assistantId: assistant.id,
      threadId: "",
      messages: [],
    };

    newThreadList.push(newThread);
  }

  return newThreadList;
};

export const getFunctionsWithEnabled = (
  functions: AssistantFunctionNames[],
  tools: AssistantToolsObject[]
) => {
  return functions.map((functionName) => {
    const isFunctionEnabled = tools.find(
      (tool) =>
        tool.type === "function" &&
        tool.function?.name === functionName.function_name
    );

    return {
      ...functionName,
      enabled: isFunctionEnabled ? true : false,
    };
  });
};

export const updateAssistantToolsAndResources = (
  assistant: LocalAssistantInfo
): LocalAssistantInfo => {
  const assistantCopy = { ...assistant };
  let currentTools = assistantCopy.tools ? [...assistantCopy.tools] : [];
  let currentToolResouces = { ...assistantCopy.tool_resources };

  // fileSearch update
  const fileSearchToolsObject = fileSearchUpdate(
    assistantCopy,
    currentTools,
    currentToolResouces
  );
  currentTools = fileSearchToolsObject.currentToolsCopy;
  currentToolResouces = fileSearchToolsObject.currentToolResoucesCopy;

  // responseFormat update
  if (assistantCopy.localResponseFormat === "json_object") {
    currentTools = currentTools.filter((tool) => tool.type === "function");
  }
  if (
    assistantCopy.localResponseFormat === "auto" &&
    !currentTools.find((tool) => tool.type === "code_interpreter")
  ) {
    currentTools.push({ type: "code_interpreter" });
  }

  assistantCopy.tools = currentTools;
  assistantCopy.tool_resources = currentToolResouces;
  return assistantCopy;
};

const fileSearchUpdate = (
  assistantCopy: LocalAssistantInfo,
  currentTools: AssistantToolsObject[],
  currentToolResouces: AssistantToolResources
) => {
  let currentToolsCopy = [...currentTools];
  let currentToolResoucesCopy = { ...currentToolResouces };

  if (
    assistantCopy.localFileSearchId &&
    assistantCopy.localFileSearchId !== "emptyId"
  ) {
    const fileSearchTool = currentTools.find(
      (tool) => tool.type === "file_search"
    );
    if (!fileSearchTool) {
      currentToolsCopy.push({ type: "file_search" });
    }

    const fileSearchValue =
      currentToolResoucesCopy.file_search?.vector_store_ids[0];
    if (
      !fileSearchValue ||
      fileSearchValue !== assistantCopy.localFileSearchId
    ) {
      currentToolResoucesCopy.file_search = {
        vector_store_ids: [assistantCopy.localFileSearchId],
      };
    }
  } else {
    currentToolsCopy = currentToolsCopy.filter(
      (tool) => tool.type !== "file_search"
    );
    currentToolResoucesCopy.file_search?.vector_store_ids?.splice(0, 1);
  }

  return { currentToolsCopy, currentToolResoucesCopy };
};

export const getFilteredAssistantToPost = (
  assistant: LocalAssistantInfo
): AssistantPostInfo => {
  const { id: _, ...restAssistant }: LocalAssistantInfo = assistant;
  const assistantCopy = { ...restAssistant, description: "" };

  assistantCopy.name.trim();
  assistantCopy.instructions.trim();

  delete assistantCopy.object;
  delete assistantCopy.created_at;

  delete assistantCopy.localAvailableFunctions;
  delete assistantCopy.localFileSearchId;
  delete assistantCopy.localFunctionInfo;
  delete assistantCopy.localResponseFormat;
  delete assistantCopy.localJustSwitchedToJson;

  return assistantCopy;
};
