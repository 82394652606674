import axios from "axios";
import { getThreadAccessToken } from "../utils";
import { APP_ACCESS_TOKEN_NAME, APP_REFRESH_TOKEN_NAME } from "../constants";

let APP_BOT_API_URL = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  APP_BOT_API_URL = process.env.REACT_APP_BOT_URL || "/";
} else {
  // @ts-ignore
  APP_BOT_API_URL = window.__RUNTIME_CONFIG__?.REACT_APP_BOT_URL;
}

export let appBotApi = axios.create({
  baseURL: APP_BOT_API_URL,
});

export const recreateAppBotApi = (
  setUserInfo: (value: null) => void,
  replacementToken: boolean,
  baseUrl?: string
) => {
  appBotApi = axios.create({
    baseURL: baseUrl ? baseUrl : APP_BOT_API_URL,
  });

  appBotApi.interceptors.request.use((request) => {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${getThreadAccessToken()}`;
    }
    // if (replacementToken) {
    //   request.headers["OpenAI-Beta"] = "assistants=v2";
    // }
    return request;
  });

  appBotApi.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem(APP_ACCESS_TOKEN_NAME);
        localStorage.removeItem(APP_REFRESH_TOKEN_NAME);
        setUserInfo(null);
      }
      return Promise.reject(error);
    }
  );
};
