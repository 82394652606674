import { useRef, useState } from "react";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import { AppInput } from "../../../shared/components/AppInput";
import { AppInputBase } from "../../../shared/components/AppInputBase";
import { AppLabel } from "../../../shared/components/AppLabel";
import { AppSelect } from "../../../shared/components/AppSelect";
import { AppSelectOption } from "../../../shared/components/AppSelect/AppSelectOption";
import { AppSlider } from "../../../shared/components/AppSlider";
import { AssistantInfo, CompModel } from "../../../services/api/methodsTypes";
import {
  getAssistantFunctionSchema,
  getFileContent,
  updateAssistant,
} from "../../../services/api/methods";
import { AppLoading } from "../../../shared/components/AppLoading";
import {
  LocalAssistantInfo,
  StoreDataWithPlaceholder,
} from "../../../pages/AssistantPage";
import {
  getFilteredAssistantToPost,
  updateAssistantToolsAndResources,
} from "../../../shared/utils/assistantPageFunctions";
import { AssistantFunctionBlock } from "./AssistantFunctionBlock";
import { AppToggleButtonGroup } from "../../../shared/components/AppToggleButtonGroup";
import { AppToggleButton } from "../../../shared/components/AppToggleButtonGroup/AppToggleButton";
import classNames from "classnames";
import { showError } from "../../../shared/utils/showError/showError";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  models: CompModel[] | null;
  selectedAssistant: LocalAssistantInfo;
  changeAssistantList: (assistant: AssistantInfo) => void;
  stores: StoreDataWithPlaceholder[] | null;
  isFunctionsLoading: boolean;
  isReplacementToken: boolean;
  isSendDisabled: boolean;
}

export const AssistMain: React.FC<Props> = ({
  className,
  models,
  selectedAssistant,
  changeAssistantList,
  stores,
  isFunctionsLoading,
  isReplacementToken,
  isSendDisabled,
}) => {
  const [isFunctionLoading, setIsFunctionLoading] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Изменение имени и описания
  const handleInputChange = (event: any, type: "name" | "instructions") => {
    const assistantCopy = { ...selectedAssistant };
    assistantCopy[type] = event.target.value;
    changeAssistantList(assistantCopy);

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      updateAssistant(
        selectedAssistant.id,
        getFilteredAssistantToPost(assistantCopy)
      );
    }, 1500);
  };

  // Изменение температуры
  const handleTemperatureChange = (
    _: any,
    newValue: number | number[],
    __: number,
    isCommited?: boolean
  ) => {
    const assistantCopy = { ...selectedAssistant };
    assistantCopy.temperature = (newValue as number) / 100;
    changeAssistantList(assistantCopy);

    if (isCommited) {
      updateAssistant(
        selectedAssistant.id,
        getFilteredAssistantToPost(assistantCopy)
      );
    }
  };

  // Изменение модели
  const handleModelChange = (event: any) => {
    const assistantCopy = { ...selectedAssistant };
    assistantCopy.model = event.target.value;
    updateAssistant(
      selectedAssistant.id,
      getFilteredAssistantToPost(assistantCopy)
    )
      .then(() => changeAssistantList(assistantCopy))
      .catch(() =>
        showError("Эта модель не поддерживает работу с ассистентами")
      );
  };

  // Переключение режима ответа
  const handleResponseFormatToggle = (event: any) => {
    const assistantCopy = { ...selectedAssistant };
    assistantCopy.localResponseFormat = event.target.value;
    switch (event.target.value) {
      case "auto":
        assistantCopy.response_format = "auto";
        assistantCopy.localJustSwitchedToJson = false;
        break;
      case "json_object":
        assistantCopy.response_format = {
          type: "json_object",
        };
        assistantCopy.localJustSwitchedToJson = true;
        break;
    }

    // Обновляем tools потому что нужно убрать code_interpreter для работы json_object ответа
    const updatedAssistantCopy =
      updateAssistantToolsAndResources(assistantCopy);
    changeAssistantList(updatedAssistantCopy);
    updateAssistant(
      selectedAssistant.id,
      getFilteredAssistantToPost(updatedAssistantCopy)
    );
  };

  // Изменение файла для поиска
  const handleStoreChange = (event: any) => {
    const assistantCopy = { ...selectedAssistant };
    assistantCopy.localFileSearchId = event.target.value;
    const updatedAssistantCopy =
      updateAssistantToolsAndResources(assistantCopy);

    changeAssistantList(updatedAssistantCopy);
    updateAssistant(
      selectedAssistant.id,
      getFilteredAssistantToPost(updatedAssistantCopy)
    );
  };

  // Включение/выключение функций
  const handleFunctionChange = (event: any, functionName: string) => {
    const assistantCopy = { ...selectedAssistant };
    if (!assistantCopy.localAvailableFunctions) return;
    const updatedAssistantFunctions = assistantCopy.localAvailableFunctions.map(
      (func) =>
        func.function_name === functionName
          ? { ...func, enabled: event.target.checked }
          : func
    );

    if (event.target.checked === true) {
      const functionSchema = assistantCopy.localFunctionInfo?.find(
        (func) => func.function?.name === functionName
      )?.function;
      if (functionSchema) {
        assistantCopy.tools.push({
          type: "function",
          function: functionSchema,
        });
        assistantCopy.localAvailableFunctions = updatedAssistantFunctions;
        changeAssistantList(assistantCopy);
        updateAssistant(
          assistantCopy.id,
          getFilteredAssistantToPost(assistantCopy)
        );
      } else {
        setIsFunctionLoading(true);
        getAssistantFunctionSchema(assistantCopy.id, functionName)
          .then((schema) => {
            if (!assistantCopy.localFunctionInfo) {
              assistantCopy.localFunctionInfo = [
                { type: "function", function: schema.schema },
              ];
            } else {
              assistantCopy.localFunctionInfo.push({
                type: "function",
                function: schema.schema,
              });
            }
            assistantCopy.tools.push({
              type: "function",
              function: schema.schema,
            });
            assistantCopy.localAvailableFunctions = updatedAssistantFunctions;
            changeAssistantList(assistantCopy);
            updateAssistant(
              assistantCopy.id,
              getFilteredAssistantToPost(assistantCopy)
            );
          })
          .finally(() => setIsFunctionLoading(false));
      }
    }

    if (event.target.checked === false) {
      assistantCopy.localAvailableFunctions = updatedAssistantFunctions;
      assistantCopy.tools = assistantCopy.tools.filter(
        (tool) => tool.function?.name !== functionName
      );
      changeAssistantList(assistantCopy);
      updateAssistant(
        assistantCopy.id,
        getFilteredAssistantToPost(assistantCopy)
      );
    }
  };

  const calculateValue = (value: number) => {
    return value / 100;
  }

  return (
    <div className={classNames(styles.layout, className)}>
      <div className={styles["top-container"]}>
        <AppUnderlinedTitle className={styles.title} title="Основное" />
        <AppLabel className={styles.text}>
          {`ID Ассистента: ${selectedAssistant.id}`}
        </AppLabel>
        <AppInput
          value={selectedAssistant.name}
          onChange={(e) => handleInputChange(e, "name")}
          className={styles.input}
          placeholder="Название ассистента"
        />
        <AppInputBase
          value={selectedAssistant.instructions}
          onChange={(e) => handleInputChange(e, "instructions")}
          className={styles["text-area"]}
          classes={{
            input: styles["text-area-inner"],
          }}
          placeholder="Инструкция"
          rows={10}
        />

        <div className={styles["select-container"]}>
          <AppLabel className={styles.text}>Выбор модели</AppLabel>
          <div className={styles["models-container"]}>
            {models?.length ? (
              <>
                <AppSelect
                  value={selectedAssistant.model}
                  onChange={handleModelChange}
                  className={classNames(styles.select, styles["model-select"])}
                  itemText={
                    models?.find(
                      (model) => model.model_name === selectedAssistant.model
                    )?.friendly_name
                  }
                  disabled={isSendDisabled}
                >
                  {models?.map((model, index) => (
                    <AppSelectOption key={index} value={model.model_name}>
                      {model.friendly_name}
                    </AppSelectOption>
                  ))}
                </AppSelect>
                <AppToggleButtonGroup
                  value={selectedAssistant.localResponseFormat}
                  exclusive
                  onChange={handleResponseFormatToggle}
                  disabled={isSendDisabled}
                >
                  <AppToggleButton value={"auto"}>AUTO</AppToggleButton>
                  <AppToggleButton value={"json_object"}>JSON</AppToggleButton>
                </AppToggleButtonGroup>
              </>
            ) : (
              <AppLoading small />
            )}
          </div>

          {isReplacementToken &&
            (!!selectedAssistant.localAvailableFunctions?.length ||
              isFunctionsLoading) && (
              <div className={styles["function-container"]}>
                <AppUnderlinedTitle className={styles.title} title="Функции">
                  {/* <AppButton
                  small
                  buttonType="text"
                  onClick={console.log}
                  icon={<PlusIcon />}
                  disabled={isFunctionsLoading}
                >
                  Добавить функцию
                </AppButton> */}
                </AppUnderlinedTitle>
                <div className={styles["function-list"]}>
                  {isFunctionsLoading ? (
                    <AppLoading small />
                  ) : (
                    selectedAssistant.localAvailableFunctions &&
                    selectedAssistant.localAvailableFunctions.map(
                      (asstFunction) => (
                        <AssistantFunctionBlock
                          className={styles["function-block"]}
                          key={asstFunction.function_name}
                          functionName={asstFunction.friendly_name}
                          isActive={asstFunction.enabled}
                          handleSwitchChange={(e) =>
                            handleFunctionChange(e, asstFunction.function_name)
                          }
                        />
                      )
                    )
                  )}
                  {/* {isFunctionLoading && (
                <div className={styles["functions-loading"]}>
                  <AppLoading small />
                </div>
                )} */}
                </div>
              </div>
            )}

          <AppLabel className={styles.text}>Подключить базу знаний</AppLabel>
          {stores?.length ? (
            <AppSelect
              value={selectedAssistant.localFileSearchId}
              onChange={handleStoreChange}
              className={styles.select}
              itemText={
                stores?.find(
                  (store) => store.id === selectedAssistant.localFileSearchId
                )?.name
              }
              disabled={
                stores.length === 1 ||
                selectedAssistant.localResponseFormat !== "auto"
              }
            >
              {stores?.map((store) => (
                <AppSelectOption key={store.id} value={store.id}>
                  {store.name}
                </AppSelectOption>
              ))}
            </AppSelect>
          ) : (
            <AppLoading small />
          )}
        </div>

        <div className={styles["slider-container"]}>
          <AppUnderlinedTitle
            className={styles["temperature-title"]}
            title="Температура"
          />
          <p className={styles["temperature-label"]}>
            {selectedAssistant.temperature.toFixed(2)}
          </p>
          <AppSlider
            value={selectedAssistant.temperature * 100}
            min={1}
            max={100}
            scale={calculateValue}
            onChange={(e, value, activeThumb) =>
              handleTemperatureChange(e, value, activeThumb)
            }
            onChangeCommitted={(e, value) =>
              handleTemperatureChange(e, value, 0, true)
            }
          />
        </div>
      </div>
    </div>
  );
};
