import { appRouterPaths } from "../../../services/navigation/urls";
import { ClassificationPage } from "../../../pages/ClassificationPage";
import { AppRouteProps } from "../types";

export const classificationPageRoute: AppRouteProps = {
  path: appRouterPaths.classification,
  element: <ClassificationPage />,
  link: "/classification_model_education",
  name: "Классификация тестов",
};
