import { useEffect, useRef, useState } from "react";
import { AppChatMessage } from "./AppChatMessage";
import { LocalAssistantMessage } from "../../../../pages/AssistantPage";
import styles from "./styles.module.css";
import classNames from "classnames";

interface Props {
  className?: string;
  displayedMessages: LocalAssistantMessage[];
  isMessagesLoading: boolean;
  assistantMode?: boolean;
  customLoadingMessage?: string | null;
}

export const AppChat: React.FC<Props> = ({
  className,
  assistantMode = false,
  displayedMessages,
  isMessagesLoading = false,
  customLoadingMessage,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Скролл в конец чата при новом сообщении
    // Без таймаута скролит в начало сообщения
    const timeout = setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, 1);

    return () => clearTimeout(timeout);
  }, [displayedMessages.length]);

  return (
    <>
      <div
        className={classNames(styles.chat, className)}
        ref={chatContainerRef}
      >
        {!displayedMessages?.length ? (
          <span className={styles.loading}>Пустой чат</span>
        ) : null}
        {displayedMessages?.map((message, index) => {
          if (message.role !== "system") {
            return (
              <AppChatMessage
                key={index}
                avatar={!assistantMode}
                from={message.role}
                children={message.content}
                attachedfileName={message.fileName}
                isMessageJson={message.isJson}
              />
            );
          }
        })}

        {isMessagesLoading ? (
          <>
            <span className={styles.loading}>
              {customLoadingMessage ?? "Загрузка ответа..."}
            </span>
          </>
        ) : null}
      </div>
    </>
  );
};
