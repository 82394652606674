import { Button } from "@mui/material";
import classNames from "classnames";
import type { ButtonProps } from "@mui/material/Button";
import type { FC } from "react";
import styles from "./styles.module.css";

interface Props extends ButtonProps {
  buttonType: "contained" | "outlined" | "text";
  icon?: JSX.Element;
  small?: boolean;
  warning?: boolean;
  disabled?: boolean;
}

export const AppButton: FC<Props> = ({
  className,
  children,
  icon,
  buttonType,
  small,
  warning,
  disabled,
  ...props
}) => {
  return (
    <Button
      variant={buttonType}
      startIcon={icon}
      className={classNames(
        styles.button,
        styles[buttonType],
        {
          [styles.small]: small,
          [styles.warning]: warning,
          [styles.disabled]: disabled,
        },
        className
      )}
      classes={{
        icon: classNames(styles.icon),
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};
