import type { AppIconProps } from "../IconTypes";

export const PlusIcon: React.FC<AppIconProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width || "16"}
      height={height || "16"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.00001 3.33331V12.6666V3.33331Z" fill="#39A37C" />
      <path d="M3.33334 7.99998H12.6667H3.33334Z" fill="#39A37C" />
      <path
        d="M8.00001 3.33331V12.6666M3.33334 7.99998H12.6667"
        stroke="#39A37C"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
