import classNames from "classnames";
import { AppDialog } from "..";
import { AlertIcon } from "../../../icons/AlertIcon";
import { AppButton } from "../../AppButton";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  dialogOpen: boolean;
  deletingInfo: any;
  handleDialogClose: () => void;
  onDelete: (id: string) => void;
}

export type DeleteInfo = {
  name: string;
  id: string;
  type?: string;
};

export const defaultDeleteInfo: DeleteInfo = {
  name: "",
  id: "",
  type: "",
};

export const AppDeleteDialog: React.FC<Props> = ({
  className,
  dialogOpen,
  deletingInfo,
  handleDialogClose,
  onDelete,
}) => {
  return (
    <AppDialog
      classes={{ paper: classNames(styles.dialog, className) }}
      open={dialogOpen}
    >
      <div className={styles["warning-container"]}>
        <AlertIcon className={styles["warning-icon"]} />
        <p className={styles.warning}>
          Вы точно хотите удалить{" "}
          <span className={styles.name}>{deletingInfo.name || ""}</span>?
        </p>
      </div>
      <div className={styles["dialog-button-container"]}>
        <AppButton buttonType="outlined" onClick={handleDialogClose}>
          Отмена
        </AppButton>
        <AppButton
          buttonType="outlined"
          warning
          onClick={() => onDelete?.(deletingInfo.id)}
        >
          Удалить
        </AppButton>
      </div>
    </AppDialog>
  );
};
