import { useState, useEffect } from "react";
import { AppIconButton } from "../../../../shared/components/AppIconButton";
import { CopyIcon } from "../../../../shared/icons/CopyIcon";
import { remark } from "remark";
import html from "remark-html";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  from: "user" | "assistant" | "system";
  children: string;
  className?: string;
}

export const CompMessage: React.FC<Props> = ({ from, children, className }) => {
  const [htmlContent, setHtmlContent] = useState<string>("");

  useEffect(() => {
    if (from === "assistant") {
      const processMarkdown = async () => {
        const processedContent = await remark().use(html).process(children);
        setHtmlContent(processedContent.toString());
      };

      processMarkdown();
    } else {
      setHtmlContent(children);
    }
  }, [children]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.right]: from === "user",
      })}
    >
      <div className={classNames(styles.message, styles[from], className)}>
        <div
          className={styles.inner}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </div>
      {from === "assistant" && (
        <AppIconButton
          className={styles["copy-button"]}
          onClick={() => {
            navigator.clipboard.writeText(children || "");
          }}
        >
          <CopyIcon />
        </AppIconButton>
      )}
    </div>
  );
};
