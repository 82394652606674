import { ToggleButton, ToggleButtonProps } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props extends ToggleButtonProps {
  className?: string;
}

export const AppToggleButton: React.FC<Props> = ({ className, ...props }) => {
  return (
    <ToggleButton
      className={classNames(styles.button, className)}
      classes={{
        selected: styles.selected,
        disabled: styles.disabled,
      }}
      disableRipple
      {...props}
    ></ToggleButton>
  );
};
