import { APP_ACCESS_TOKEN_NAME, APP_REPLACEMENT_TOKEN_NAME } from "./constants";

export const getAccessToken = () => {
  return localStorage.getItem(APP_ACCESS_TOKEN_NAME);
};

export const getThreadAccessToken = () => {
  return (
    localStorage.getItem(APP_REPLACEMENT_TOKEN_NAME) ||
    localStorage.getItem(APP_ACCESS_TOKEN_NAME)
  );
};
