import { Route, Routes, useNavigate } from "react-router-dom";
import { Page } from "../../pages/Page";
import { embeddingsPageRoute } from "./embeddings/config";
import { comparisonPageRoute } from "./comparison/config";
import { classificationPageRoute } from "./classification/config";
import { assistantPageRoute } from "./assistant/config";
import { CircularProgress } from "@mui/material";
import { LoginPage } from "../../pages/LoginPage";
import {
  APP_ACCESS_TOKEN_NAME,
  APP_REFRESH_TOKEN_NAME,
  APP_REPLACEMENT_TOKEN_NAME,
} from "../../services/api/constants";
import { getUserInfo } from "../../services/api/methods";
import { useEffect, useState } from "react";
import { recreateAppThreadApi } from "../../services/api/instances/threadApiInstance";
import { recreateAppKeycloakApi } from "../../services/api/instances/keycloakApiInstance";
import { recreateAppModelsApi } from "../../services/api/instances/modelsApiInstance";
import { recreateAppClassificatorToolsApi } from "../../services/api/instances/classificatorApiInstance";
import { recreateAppVectorToolsApi } from "../../services/api/instances/vectorApiInstance";
import { useProfile } from "../../services/profile";
import { databasePageRoute } from "./database/config";
import { chatbotPageRoute } from "./chatbot/config";
import { recreateAppBotApi } from "../../services/api/instances/botApiInstance";

export const routesArray = [
  embeddingsPageRoute,
  comparisonPageRoute,
  classificationPageRoute,
  assistantPageRoute,
  databasePageRoute,
  chatbotPageRoute,
];

export const AppRouting = () => {
  const { isLoading, setIsLoading, setUserInfo, userInfo } = useProfile();
  const [isApiCreated, setIsApiCreated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isApiCreated) {
      recreateAppThreadApi(setUserInfo, false);
      recreateAppBotApi(setUserInfo, false);
      recreateAppKeycloakApi(setUserInfo);
      recreateAppModelsApi(setUserInfo);
      recreateAppClassificatorToolsApi(setUserInfo);
      recreateAppVectorToolsApi(setUserInfo);
      setIsApiCreated(true);
    }
  }, [isApiCreated]);

  useEffect(() => {
    if (isApiCreated) {
      getUserInfo()
        .then((res) => {
          setIsLoading(false);
          setUserInfo(res);
          if (res.replacement_token) {
            localStorage.setItem(
              APP_REPLACEMENT_TOKEN_NAME,
              res.replacement_token
            );
          } else {
            localStorage.removeItem(APP_REPLACEMENT_TOKEN_NAME);
          }
          recreateAppThreadApi(
            setUserInfo,
            !!res.replacement_token,
            res.thread_url
          );
          recreateAppModelsApi(setUserInfo, res.thread_model_url);
          recreateAppBotApi(
            setUserInfo,
            !!res.replacement_token,
            res.tg_bot_service_url
          );

          const firstGroup = res["user-groups"][0];
          const firstUrl =
            routesArray.find((route) => route.link === firstGroup)?.path || "/";

          navigate(firstUrl);
        })
        .catch(() => {
          setIsLoading(false);
          setUserInfo(null);
          localStorage.removeItem(APP_ACCESS_TOKEN_NAME);
          localStorage.removeItem(APP_REFRESH_TOKEN_NAME);
          localStorage.removeItem(APP_REPLACEMENT_TOKEN_NAME);
        });
    }
  }, [isApiCreated]);

  if (isLoading) {
    return <CircularProgress color="success" />;
  }

  if (!userInfo) {
    return <LoginPage />;
  }

  return (
    <Routes>
      <Route element={<Page />}>
        {routesArray.map((route) => {
          if (userInfo["user-groups"].includes(route.link)) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            );
          }
        })}
      </Route>
    </Routes>
  );
};
