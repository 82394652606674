import { ChatbotWithChats, UserListType } from "../../../pages/ChatbotPage";
import { ChatMessageObject } from "../../../services/api/methodsTypes";

export type TokenError = {
  errorCode: number;
  message: string;
  isActive: boolean;
};

export const startTokenErrors = [
  {
    errorCode: 500,
    message: "Этот бот уже добавлен",
    isActive: false,
  },
  {
    errorCode: 404,
    message: "Неверный токен",
    isActive: false,
  },
];

// Изменение статуса валидности токена
export const setIsTokenWrong = (
  tokenErrors: TokenError[],
  setTokenErrors: (tokenErrors: TokenError[]) => void,
  isTokenWrong: boolean
) => {
  const newTokenErrors = tokenErrors.map((error) => {
    if (error.errorCode === 404) {
      return {
        ...error,
        isActive: isTokenWrong,
      };
    } else {
      return error;
    }
  });
  setTokenErrors(newTokenErrors);
};

// Получить статус валидности токена
export const getIsTokenWrong = (tokenErrors: TokenError[]) => {
  return (
    tokenErrors.find((error) => error.errorCode === 404)?.isActive || false
  );
};

// Изменение статуса существования токена
export const setIsTokenExisting = (
  tokenErrors: TokenError[],
  setTokenErrors: (tokenErrors: TokenError[]) => void,
  isTokenExisting: boolean
) => {
  const newTokenErrors = tokenErrors.map((error) => {
    if (error.errorCode === 500) {
      return {
        ...error,
        isActive: isTokenExisting,
      };
    } else {
      return error;
    }
  });
  setTokenErrors(newTokenErrors);
};

// Получить статус существования токена
export const getIsTokenExisting = (tokenErrors: TokenError[]) => {
  return (
    tokenErrors.find((error) => error.errorCode === 500)?.isActive || false
  );
};

// Отключение всех ошибок
export const resetTokenErrors = (
  tokenErrors: TokenError[],
  setTokenErrors: (tokenErrors: TokenError[]) => void
) => {
  const newTokenErrors = tokenErrors.map((error) => {
    return {
      ...error,
      isActive: false,
    };
  });
  setTokenErrors(newTokenErrors);
  return newTokenErrors;
};

// Вывод только сообщений подходящих по дате
export const getMessagesForDates = (
  messages: ChatMessageObject[],
  dateStart: Date | null | undefined,
  dateEnd: Date | null | undefined
): ChatMessageObject[] => {
  if (!dateStart || !dateEnd) return messages;
  return messages.filter((message) => {
    const messageDateString = message.created_in_db;
    const messageDate = new Date(messageDateString);
    return messageDate >= dateStart && messageDate <= dateEnd;
  });
};

// Форматирование userList стейта для сохранения в главный стейт
export const getFilteredUserList = (
  userList: UserListType,
  listType: "white_list" | "black_list"
) => {
  const localListType =
    listType === "white_list"
      ? "selectedWhitelistUsers"
      : "selectedBlacklistUsers";
  const filteredUserList: string[] = [];
  userList[localListType].forEach((user) => {
    if (user.id !== "emptyId" && !filteredUserList.includes(user.id))
      filteredUserList.push(user.id);
  });
  return filteredUserList;
};

// Форматирование главного стейта для обновления на сервере
export const getFilteredBotToPost = (
  bot: ChatbotWithChats
): ChatbotWithChats => {
  const filteredBot = { ...bot };
  if (filteredBot.asst_name === "emptyId") filteredBot.asst_name = null;
  if (filteredBot.secure_bot_name === "Без бота")
    filteredBot.secure_bot_name = null;
  if (
    filteredBot.secure_chat_id === "" ||
    filteredBot.secure_chat_id === "emptyId"
  )
    filteredBot.secure_chat_id = null;
  if (filteredBot.white_list?.length === 0) filteredBot.white_list = null;
  if (filteredBot.black_list?.length === 0) filteredBot.black_list = null;
  if (filteredBot.chats) delete filteredBot.chats;
  if (filteredBot.selectedChat) delete filteredBot.selectedChat;
  if (filteredBot.dateStart) delete filteredBot.dateStart;
  if (filteredBot.dateEnd) delete filteredBot.dateEnd;

  return filteredBot;
};
