import { MenuItem } from "@mui/material";
import classNames from "classnames";
import type { MenuItemProps } from "@mui/material";
import styles from "./styles.module.css";

export const AppSelectOption: React.FC<MenuItemProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <MenuItem
      className={classNames(styles.option, className)}
      classes={{ selected: styles.selected }}
      {...props}
    >
      {children}
    </MenuItem>
  );
};
