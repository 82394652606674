import React, { memo, useState } from "react";
import { AppChart } from "../../../shared/components/AppChart";
import { CoordinateType, Vectors } from "../../../services/api/methodsTypes";
import { CircularProgress } from "@mui/material";
import { MainToggleButton } from "./MainToggleButton";
import styles from "./styles.module.css";

interface Props {
  vectors: Vectors | null;
  isLoading: boolean;
}

export const Main: React.FC<Props> = memo(({ vectors, isLoading }) => {
  const [visibility, setVisibility] = useState<boolean>(false);
  const handleVisibility = (
    event: React.MouseEvent<HTMLElement>,
    newVisibility: boolean | null
  ) => {
    if (newVisibility !== null) {
      setVisibility(newVisibility);
    }
  };

  const cords =
    vectors?.coordinates.map((cord: CoordinateType) => {
      return {
        x: cord.x,
        y: cord.y,
        label: cord.sentence,
      };
    }) || [];

  return (
    <div className={styles.main}>
      <div className={styles["top-container"]}>
        <h2 className={styles.title}>Результат эмбеддинга текста</h2>
        <MainToggleButton
          visibility={visibility}
          handleVisibility={handleVisibility}
        />
      </div>
      <div className={styles["graph-container"]}>
        {isLoading ? (
          <CircularProgress size={60} color="success" />
        ) : (
          <AppChart cords={cords} isVisible={visibility} />
        )}
      </div>
      <div>
        <h3 className={styles["links-title"]}>Учебные материалы</h3>
        <div className={styles.links}>
          <a target="_blank" href="https://habr.com/ru/articles/812431/">
            Про эмбеддинги на Habr
          </a>
        </div>
      </div>
    </div>
  );
});
