import axios from "axios";
import { getAccessToken, getThreadAccessToken } from "../utils";
import { APP_ACCESS_TOKEN_NAME, APP_REFRESH_TOKEN_NAME } from "../constants";

let APP_MODELS_API_URL = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  APP_MODELS_API_URL = process.env.REACT_APP_THREAD_MODELS || "/";
} else {
  APP_MODELS_API_URL =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_THREAD_MODELS;
}

export let appModelsApi = axios.create({
  baseURL: APP_MODELS_API_URL,
});

export const recreateAppModelsApi = (
  setUserInfo: (value: null) => void,
  baseUrl?: string
) => {
  appModelsApi = axios.create({
    baseURL: baseUrl ? baseUrl : APP_MODELS_API_URL,
  });

  appModelsApi.interceptors.request.use((request) => {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${getThreadAccessToken()}`;
    }
    return request;
  });

  appModelsApi.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem(APP_ACCESS_TOKEN_NAME);
        localStorage.removeItem(APP_REFRESH_TOKEN_NAME);
        setUserInfo(null);
      }
      return Promise.reject(error);
    }
  );
};
