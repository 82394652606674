import classNames from "classnames";
import styles from "./styles.module.css";

export type ChartEntry = {
  label: string;
  value: number;
};

interface Props {
  data: ChartEntry[];
  className?: string;
}

const COLORS = ["#52C41A", "#FF4D4F", "#1677FF", "#9254DE", "#FA8C16"];

export const MyClassChart: React.FC<Props> = ({ data, className }) => {
  const highestValueLabel = (data: ChartEntry[]) => {
    let topValueLabel = data[0].label;
    let topValue = data[0].value;

    for (let i = 1; i < data.length; i++) {
      if (data[i].value > topValue) {
        topValue = data[i].value;
        topValueLabel = data[i].label;
      }
    }

    return topValueLabel;
  };

  return (
    <div className={classNames(styles["chart-container"], className)}>
      <h2 className={styles.title}>{highestValueLabel(data)}</h2>
      {data.map((item, index) => (
        <div key={index} className={styles.barContainer}>
          <span className={styles.label}>{item.label}</span>
          <div
            className={styles.bar}
            style={{
              width: `${item.value * 65}%`,
              backgroundColor: COLORS[index % COLORS.length],
            }}
          ></div>
          <span
            className={styles.percentage}
            hidden={item.label ? false : true}
          >
            {(item.value * 100).toFixed(0)}%
          </span>
        </div>
      ))}
    </div>
  );
};
