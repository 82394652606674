import { Outlet } from "react-router-dom";
import { AppHeader } from "../../common/Header";
import styles from "./styles.module.css";

export const Page = () => {
  return (
    <div className={styles["page-layout"]}>
      <AppHeader />
      <div className={styles["content-container"]}>
        <Outlet />
      </div>
    </div>
  );
};
