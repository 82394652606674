import { NavLink, NavLinkProps } from "react-router-dom";
import styles from "./styles.module.css";
import classNames from "classnames";

interface Props extends NavLinkProps {
  activeClassName: string;
}

export const AppNavLink: React.FC<Props> = ({
  activeClassName,
  className,
  children,
  to,
  ...props
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, className, { [activeClassName]: isActive })
      }
      {...props}
    >
      {children}
    </NavLink>
  );
};
