import type { AppIconProps } from "../IconTypes";

export const AnalyzeIcon: React.FC<AppIconProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 7.33339C13.1702 6.16029 12.626 5.07334 11.7844 4.23997C10.9428 3.40659 9.85063 2.873 8.67599 2.72139C7.62698 2.58489 6.56098 2.75969 5.6105 3.22409C4.66003 3.68848 3.867 4.42197 3.32999 5.33339M2.66666 8.66673C2.82089 9.7768 3.31668 10.8114 4.0853 11.627C4.85392 12.4427 5.85728 12.9989 6.95625 13.2187C8.05522 13.4385 9.19536 13.311 10.2186 12.8537C11.2418 12.3964 12.0973 11.6321 12.6667 10.6667M12 10.6667C12 10.8435 12.0702 11.0131 12.1953 11.1381C12.3203 11.2632 12.4898 11.3334 12.6667 11.3334C12.8435 11.3334 13.013 11.2632 13.1381 11.1381C13.2631 11.0131 13.3333 10.8435 13.3333 10.6667C13.3333 10.4899 13.2631 10.3203 13.1381 10.1953C13.013 10.0703 12.8435 10.0001 12.6667 10.0001C12.4898 10.0001 12.3203 10.0703 12.1953 10.1953C12.0702 10.3203 12 10.4899 12 10.6667ZM2.66666 5.33339C2.66666 5.51021 2.73689 5.67977 2.86192 5.8048C2.98694 5.92982 3.15651 6.00006 3.33332 6.00006C3.51013 6.00006 3.6797 5.92982 3.80473 5.8048C3.92975 5.67977 3.99999 5.51021 3.99999 5.33339C3.99999 5.15658 3.92975 4.98701 3.80473 4.86199C3.6797 4.73697 3.51013 4.66673 3.33332 4.66673C3.15651 4.66673 2.98694 4.73697 2.86192 4.86199C2.73689 4.98701 2.66666 5.15658 2.66666 5.33339ZM5.99999 8.00006C5.99999 8.53049 6.2107 9.0392 6.58578 9.41427C6.96085 9.78935 7.46956 10.0001 7.99999 10.0001C8.53042 10.0001 9.03913 9.78935 9.4142 9.41427C9.78928 9.0392 9.99999 8.53049 9.99999 8.00006C9.99999 7.46963 9.78928 6.96092 9.4142 6.58585C9.03913 6.21077 8.53042 6.00006 7.99999 6.00006C7.46956 6.00006 6.96085 6.21077 6.58578 6.58585C6.2107 6.96092 5.99999 7.46963 5.99999 8.00006Z"
        stroke="#FCFFFE"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
