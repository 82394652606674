import { useEffect, useRef, useState } from "react";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import { AppButton } from "../../../shared/components/AppButton";
import { CompMessage } from "./CompMessage";
import { ClearIcon } from "../../../shared/icons/ClearIcon";
import { SelectedValue } from "../../../pages/ComparisonPage";
import {
  createAssistant,
  createRun,
  createThread,
  deleteAssistant,
  getMessages,
  getRunStatus,
  postCompMessage,
} from "../../../services/api/methods";
import {
  AssistantInfo,
  CompFullMessageListType,
  CompModel,
  CompMessageType,
} from "../../../services/api/methodsTypes";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  instructions: string;
  requestText: string;
  models: CompModel[];
  chatNumber: number;
  selectedValues: SelectedValue[];
  sendRequestSwitch: boolean;
  setDisableCounter: (counter: any) => void;
  setRequestText: (text: string) => void;
  setIsSendDisabled: (isSendDisabled: boolean) => void;
  onDialogOpen: (chatNumber: number) => void;
}

export const findModelName = (models: CompModel[], model_name: string) => {
  return models.find((item) => item.model_name === model_name)?.friendly_name;
};

export const CompChat: React.FC<Props> = ({
  className,
  instructions,
  requestText,
  models,
  chatNumber,
  selectedValues,
  sendRequestSwitch,
  setDisableCounter,
  setRequestText,
  setIsSendDisabled,
  onDialogOpen,
}) => {
  const [assistant, setAssistant] = useState<AssistantInfo>();
  const [thread, setThread] = useState<any>();
  const [run, setRun] = useState<any>();
  const [messageList, setMessageList] =
    useState<CompFullMessageListType | null>();
  const [isLoading, setIsLoading] = useState(false);

  // Scroll to the bottom for every new message
  const chatContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messageList]);

  // On first render
  useEffect(() => {
    createThread().then(setThread).catch(console.error);
  }, []);

  // On model select
  useEffect(() => {
    if (assistant?.id) {
      deleteAssistant(assistant.id).catch(console.error);
    }

    const assistantPostObj = {
      model: selectedValues[chatNumber].value,
      instructions: instructions,
    };

    if (selectedValues[chatNumber].value === "") return;

    createAssistant(assistantPostObj).then(setAssistant).catch(console.error);
  }, [selectedValues[chatNumber].value, instructions]);

  // On send request
  useEffect(() => {
    const messageObj: CompMessageType = {
      role: "user",
      content: requestText,
    };

    if (requestText !== "" && assistant) {
      setIsLoading(true);
      setIsSendDisabled(true);

      postCompMessage(thread?.id, messageObj)
        .catch(console.error)
        .finally(() => {
          getMessages(thread?.id).then(setMessageList).catch(console.error);
        });

      setRequestText("");

      if (!assistant.id) return;
      const runObj = {
        assistant_id: assistant.id,
      };
      createRun(thread?.id, runObj).then(setRun).catch(console.error);
    }
  }, [sendRequestSwitch]);

  // Run status update
  useEffect(() => {
    const timer = setInterval(() => {
      if (thread && run && run.status !== "completed") {
        getRunStatus(thread?.id, run?.id).then(setRun).catch(console.error);
      }
    }, 1000);

    return () => clearInterval(timer);
  });

  // On run change
  useEffect(() => {
    if (run && run.status === "completed") {
      getMessages(thread?.id).then(setMessageList).catch(console.error);

      setDisableCounter((prev: number) => prev + 1);
      setIsLoading(false);
    }
  }, [run]);

  const handleThreadReset = () => {
    createThread().then(setThread).catch(console.error);
    setMessageList(null);
  };

  // // Testing
  // useEffect(() => {
  //   console.log(
  //     chatNumber,
  //     "assistant:",
  //     assistant,
  //     "thread:",
  //     thread,
  //     "run:",
  //     run,
  //     "messageList:",
  //     messageList
  //   );
  // }, [messageList, run, thread, assistant]);

  return (
    <div className={classNames(styles.container, className)}>
      <p>threadID: {thread?.id}</p>
      <p>assistantID: {assistant?.id}</p>
      <AppUnderlinedTitle
        title={
          findModelName(models, selectedValues[chatNumber].value) ||
          "Выберите модель"
        }
        buttonTitle
        className={styles.title}
        buttonClassName={styles["model-button"]}
        onClick={() => onDialogOpen(chatNumber)}
        disabled={isLoading}
      >
        <div className={styles["button-container"]}>
          <AppButton
            className={classNames({ [styles.disabled]: isLoading })}
            buttonType="contained"
            icon={<ClearIcon />}
            small
            onClick={handleThreadReset}
            disabled={isLoading}
          >
            Очистить
          </AppButton>
        </div>
      </AppUnderlinedTitle>
      <div className={styles["chat-container"]} ref={chatContainerRef}>
        {!messageList ? (
          <span className={styles.loading}>Пустой чат</span>
        ) : null}
        {messageList?.data.map((message) => {
          if (message.role !== "system") {
            return (
              <CompMessage
                key={message.id}
                from={message.role}
                children={message.content[0].text.value}
              />
            );
          }
        })}

        {isLoading ? (
          <span className={styles.loading}>Загрузка ответа...</span>
        ) : null}
      </div>
    </div>
  );
};
