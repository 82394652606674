import { appVectorToolsApi } from "./instances/vectorApiInstance";
import { appClassificatorToolsApi } from "./instances/classificatorApiInstance";
import { appThreadApi } from "./instances/threadApiInstance";
import { appModelsApi } from "./instances/modelsApiInstance";
import {
  Model,
  PostObjType,
  Vectors,
  ClassPostObjType,
  PredictionObject,
  AssistantCreatePostObjType,
  AssistantInfo,
  RunPostObjType,
  RunInfo,
  CompMessageType,
  CompFullMessageListType,
  CompFullMessageType,
  ModelsArray,
  Tokens,
  UserInfo,
  AssistantList,
  NewStoreObj,
  StoreData,
  StoreList,
  UploadedFile,
  FilePostObjType,
  DeletionStatus,
  FileList,
  NewStoreData,
  StoreFileObject,
  StoreFileObjectList,
  BotListObject,
  BotObject,
  NewBotObject,
  ChatListObject,
  MessageListObject,
  TelegramBotStatus,
  AssistantPostInfo,
  AssistantFunctionNames,
  AssistantToolsFunctionSchema,
  AssistantFunctionRunOutput,
  AssistantFunctionRunPostObjType,
  AssistantFunctionToolOutput,
  OpenAiModelArray,
} from "./methodsTypes";
import {
  APP_KEYCLOAK_CLIENT_ID,
  appKeycloakApi,
} from "./instances/keycloakApiInstance";
import { APP_REFRESH_TOKEN_NAME } from "./constants";
import { appBotApi } from "./instances/botApiInstance";
import { appTelegramApi } from "./instances/telegramApiInstance";
import axios, { AxiosError, AxiosResponse } from "axios";
import { showError } from "../../shared/utils/showError/showError";

export const getModels = (): Promise<Model[]> => {
  return appVectorToolsApi
    .get("/get_models", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      console.log(error);
      throw error;
    });
};

export const getVectors = (postObj: PostObjType): Promise<Vectors> => {
  return appVectorToolsApi
    .post("/get_vectors", postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      console.log(error);
      throw error;
    });
};

export const getClasses = (
  postObj: ClassPostObjType
): Promise<PredictionObject> => {
  return appClassificatorToolsApi
    .post("/nlp_class", postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getClasses error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getCompModels = (): Promise<ModelsArray | OpenAiModelArray> => {
  return appModelsApi
    .get("/v1/models", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getCompModels error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getAssistants = (): Promise<AssistantList> => {
  return appThreadApi
    .get("/v1/assistants?limit=100", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getAssistants error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const createAssistant = (
  postObj: AssistantCreatePostObjType
): Promise<AssistantInfo> => {
  return appThreadApi
    .post("/v1/assistants", postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("createAssistant error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const updateAssistant = (
  id: string,
  postObj: AssistantPostInfo
): Promise<AssistantInfo> => {
  return appThreadApi
    .post("/v1/assistants/" + id, postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("updateAssistant error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const deleteAssistant = (id: string): Promise<string> => {
  return appThreadApi
    .delete("/v1/assistants/" + id, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("deleteAssistant error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getAssistantFunctions = (
  id: string
): Promise<{ functions: AssistantFunctionNames[] }> => {
  return appBotApi
    .get("/v1/function/" + id, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getAssistantFunctions error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getAssistantFunctionSchema = (
  assistantId: string,
  functionName: string
): Promise<{ schema: AssistantToolsFunctionSchema }> => {
  return appBotApi
    .get(`/v1/function/${assistantId}/${functionName}/schema`, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getAssistantFunctionSchema error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const runAssistantFunction = (
  functionName: string,
  postObj: AssistantFunctionRunPostObjType
): Promise<AssistantFunctionRunOutput> => {
  return appBotApi
    .post(`/v1/function/${functionName}/run`, postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("runAssistantFunction error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const submitAssistantToolOutputs = (
  threadId: string,
  runId: string,
  postObj: { tool_outputs: AssistantFunctionToolOutput[] }
): Promise<RunInfo> => {
  return appThreadApi
    .post(
      `/v1/threads/${threadId}/runs/${runId}/submit_tool_outputs`,
      postObj,
      {
        withCredentials: false,
      }
    )
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("submitAssistantToolOutputs error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const createThread = (): Promise<{ id: string }> => {
  return appThreadApi
    .post(
      "/v1/threads",
      {},
      {
        withCredentials: false,
      }
    )
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("createThread error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const createRun = (
  threadId: string,
  postObj: RunPostObjType
): Promise<RunInfo> => {
  return appThreadApi
    .post("/v1/threads/" + threadId + "/runs", postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("createRun error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getRunStatus = (
  threadId: string,
  runId: string
): Promise<RunInfo> => {
  return appThreadApi
    .get("/v1/threads/" + threadId + "/runs/" + runId, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getRunStatus error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const postCompMessage = (
  threadId: string,
  postObj: CompMessageType
): Promise<CompFullMessageType> => {
  return appThreadApi
    .post("/v1/threads/" + threadId + "/messages", postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("postCompMessage error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getMessages = (
  threadId: string
): Promise<CompFullMessageListType> => {
  return appThreadApi
    .get("/v1/threads/" + threadId + "/messages?limit=20&order=asc", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getMessages error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getStores = (): Promise<StoreList> => {
  return appThreadApi
    .get("/v1/vector_stores", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getStores error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const createStore = (postObj: NewStoreObj): Promise<StoreData> => {
  return appThreadApi
    .post("/v1/vector_stores", postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("createStore error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getStore = (id: string): Promise<StoreData> => {
  return appThreadApi
    .get("/v1/vector_stores/" + id, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getStore error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const updateStore = (
  id: string,
  postObj: NewStoreData
): Promise<StoreData> => {
  return appThreadApi
    .post("/v1/vector_stores/" + id, postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("updateStore error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const deleteStore = (id: string): Promise<DeletionStatus> => {
  return appThreadApi
    .delete("/v1/vector_stores/" + id, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("deleteStore error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const uploadFile = (postObj: FilePostObjType): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append("file", postObj.file);
  formData.append("purpose", postObj.purpose);

  return appThreadApi
    .post("/v1/files", formData, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("uploadFile error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getFiles = (): Promise<FileList> => {
  return appThreadApi
    .get("/v1/files", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getFiles error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getFile = (id: string): Promise<UploadedFile> => {
  return appThreadApi
    .get("/v1/files/" + id, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getFile error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getFileContent = (id: string): Promise<Blob> => {
  return appThreadApi
    .get("/v1/files/" + id + "/content", {
      withCredentials: false,
      responseType: "blob",
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getFileContent error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const deleteFile = (id: string): Promise<DeletionStatus> => {
  return appThreadApi
    .delete("/v1/files/" + id, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("deleteFile error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const createStoreFile = (
  storeId: string,
  fileID: string
): Promise<StoreFileObject> => {
  return appThreadApi
    .post(
      "/v1/vector_stores/" + storeId + "/files",
      { file_id: fileID },
      {
        withCredentials: false,
      }
    )
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("createStoreFile error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getStoreFiles = (
  storeId: string
): Promise<StoreFileObjectList> => {
  return appThreadApi
    .get("/v1/vector_stores/" + storeId + "/files", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getStoreFiles error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getStoreFile = (
  storeId: string,
  fileId: string
): Promise<StoreFileObject> => {
  return appThreadApi
    .get("/v1/vector_stores/" + storeId + "/files/" + fileId, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getStoreFile error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const deleteStoreFile = (
  storeId: string,
  fileId: string
): Promise<DeletionStatus> => {
  return appThreadApi
    .delete("/v1/vector_stores/" + storeId + "/files/" + fileId, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("deleteStoreFile error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getBots = (): Promise<BotListObject> => {
  return appBotApi
    .get("/v1/bots", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getBots error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const createBot = (postObj: NewBotObject): Promise<BotObject> => {
  return appBotApi
    .post("/v1/bots", postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("createBot error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getBot = (ref: string): Promise<BotObject> => {
  return appBotApi
    .get("/v1/bots/" + ref, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getBot error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const updateBot = (
  ref: string,
  postObj: BotObject
): Promise<BotObject> => {
  return appBotApi
    .post("/v1/bots/" + ref, postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("updateBot error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const deleteBot = (ref: string): Promise<string> => {
  return appBotApi
    .delete("/v1/bots/" + ref, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("deleteBot error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getBotChats = (ref: string): Promise<ChatListObject> => {
  return appBotApi
    .get("/v1/bots/" + ref + "/chats", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getBotChats error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const updateChatAssistantName = (
  ref: string,
  chat_id: string,
  postObj: { asst_name: string }
): Promise<string> => {
  return appBotApi
    .post("/v1/bots/" + ref + "/chats/" + chat_id, postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("updateChatAssistantName error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getChatMessages = (
  ref: string,
  chat_id: string
): Promise<MessageListObject> => {
  return appBotApi
    .get("/v1/bots/" + ref + "/chats/" + chat_id + "/messages", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getChatMessages error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const verifyBot = (token: string): Promise<TelegramBotStatus> => {
  return appTelegramApi
    .get("/bot" + token + "/getMe", {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { ok: false };
      }
      showError("verifyBot error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const updateChatAssistant = (
  ref: string,
  chatId: string,
  postObj: { asst_name: string }
): Promise<string> => {
  return appBotApi
    .post("/v1/bots/" + ref + "/chats/" + chatId, postObj, {
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("updateChatAssistant error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const login = (username: string, password: string): Promise<Tokens> => {
  const params = new URLSearchParams();

  params.append("grant_type", "password");
  params.append("client_id", APP_KEYCLOAK_CLIENT_ID);
  params.append("username", username);
  params.append("password", password);

  return appKeycloakApi.post("/token", params).then((res) => res.data);
};

export const getUserInfo = (): Promise<UserInfo> => {
  return appKeycloakApi.get("/userinfo").then((res) => res.data);
};

export const logout = (): Promise<void> => {
  const params = new URLSearchParams();

  params.append("client_id", APP_KEYCLOAK_CLIENT_ID);
  params.append(
    "refresh_token",
    localStorage.getItem(APP_REFRESH_TOKEN_NAME) || ""
  );

  return appKeycloakApi.post("/logout", params).then((res) => res.data);
};
