import { RadioGroup, RadioGroupProps } from "@mui/material";

interface Props extends RadioGroupProps {
  className?: string;
}

export const AppRadioGroup: React.FC<Props> = ({
  className,
  children,
  ...props
}) => {
  return (
    <RadioGroup className={className} name="radio-buttons-group" {...props}>
      {children}
    </RadioGroup>
  );
};
