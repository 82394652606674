import { AppNavLink } from "../../shared/components/AppNavLink";
import { AppIconButton } from "../../shared/components/AppIconButton";
import { LoginIcon } from "../../shared/icons/LoginIcon";
import logo from "../../shared/assets/img/logo.png";
import styles from "./styles.module.css";
import { AppButton } from "../../shared/components/AppButton";
import { logout } from "../../services/api/methods";
import {
  APP_ACCESS_TOKEN_NAME,
  APP_REFRESH_TOKEN_NAME,
  APP_REPLACEMENT_TOKEN_NAME,
} from "../../services/api/constants";
import { useProfile } from "../../services/profile";
import { routesArray } from "../../app/routing";
import { LogoutIcon } from "../../shared/icons/LogoutIcon";

export const AppHeader = () => {
  const { setUserInfo, userInfo } = useProfile();

  const handleClick = () => {
    logout().then(() => {
      localStorage.removeItem(APP_ACCESS_TOKEN_NAME);
      localStorage.removeItem(APP_REFRESH_TOKEN_NAME);
      localStorage.removeItem(APP_REPLACEMENT_TOKEN_NAME);
      setUserInfo(null);
    });
  };
  return (
    <header className={styles.header}>
      <div className={styles["nav-container"]}>
        <img className={styles.logo} src={logo} alt="logo" />
        {routesArray.map((route) => {
          if (userInfo?.["user-groups"].includes(route.link)) {
            return (
              <AppNavLink
                key={route.path}
                activeClassName={styles.active}
                className={styles["header-link"]}
                to={route.path}
              >
                {route.name}
              </AppNavLink>
            );
          }
        })}
      </div>
      <div className={styles["login-container"]}>
        <AppIconButton className={styles["login-button"]} buttonType="text">
          <LoginIcon />
        </AppIconButton>
        <p className={styles.name}>{userInfo?.preferred_username}</p>
        <AppIconButton onClick={handleClick} buttonType="outlined">
          <LogoutIcon />
        </AppIconButton>
      </div>
    </header>
  );
};
