import { AppButton } from "../AppButton";
import { PlusIcon } from "../../icons/PlusIcon";
import { IconButton } from "@mui/material";
import { TrashIcon } from "../../icons/TrashIcon";
import { AppLoading } from "../AppLoading";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  addButton?: boolean;
  deleteButton?: boolean;
  menuItems: MenuItem[] | null;
  defaultName?: string;
  selectedId: string | null;
  onSelect: (id: string) => void;
  onAdd?: () => void;
  handleDialogOpen?: (id: string, name: string) => void;
  isSendDisabled?: boolean;
  isLoading?: boolean;
}

export type MenuItem = {
  id: string;
  name: string;
};

export const AppSidebar: React.FC<Props> = ({
  className,
  addButton,
  deleteButton,
  menuItems,
  defaultName,
  selectedId,
  onSelect,
  onAdd,
  handleDialogOpen,
  isSendDisabled,
  isLoading,
}) => {
  return (
    <aside className={classNames(styles.sidebar, className)}>
      {addButton && (
        <div className={styles["button-container"]}>
          <AppButton
            onClick={onAdd}
            buttonType="text"
            icon={<PlusIcon />}
            className={classNames(styles["add-button"], {
              [styles.disabled]: isSendDisabled,
            })}
            disabled={isSendDisabled}
          >
            Добавить
          </AppButton>
        </div>
      )}
      <ul className={styles.list}>
        {menuItems?.map((item) => (
          <li
            key={item.id}
            className={classNames(styles["list-item"], {
              [styles.selected]: item.id === selectedId,
              [styles.arrow]: item.id === selectedId && !deleteButton,
              [styles["select-bg"]]: item.id === selectedId,
            })}
          >
            <span
              className={classNames(styles["item-name"], {
                [styles.disabled]: isSendDisabled,
              })}
              onClick={() => {
                !isSendDisabled && item.id && onSelect(item.id);
              }}
            >
              {!item.name ? defaultName : item.name}
            </span>
            {deleteButton && (
              <IconButton
                className={classNames(styles["delete-button"], {
                  [styles.disabled]: isSendDisabled,
                })}
                onClick={() => {
                  !isSendDisabled &&
                    item.id &&
                    handleDialogOpen?.(item.id, item.name);
                }}
              >
                <TrashIcon />
              </IconButton>
            )}
          </li>
        ))}
        {isLoading && <AppLoading small />}
      </ul>
    </aside>
  );
};
