import { LocalAssistantMessage } from "../../../pages/AssistantPage";
import { BotObject, CompMessageType } from "../../../services/api/methodsTypes";

type AssistantModeMessagesObject = {
  messages: LocalAssistantMessage[];
  pageCount: number;
};

export const getAssistantModeMessages = (
  fullMessageList: LocalAssistantMessage[],
  assistantMode: boolean,
  isLoading?: boolean,
  selectedPage?: number
): AssistantModeMessagesObject => {
  if (assistantMode && fullMessageList.length) {
    const messagesLeft = isLoading ? 1 : 2;
    let lastMessages: LocalAssistantMessage[] = [];

    const userMessageCount = fullMessageList.filter(
      (message) => message.role === "user"
    ).length;

    if (selectedPage) {
      const sliceStart = (selectedPage - 1) * 2;
      const sliceEnd = sliceStart + 2;
      lastMessages = fullMessageList.slice(sliceStart, sliceEnd);
    } else {
      lastMessages = fullMessageList.slice(
        fullMessageList.length - messagesLeft
      );
    }

    return {
      messages: lastMessages,
      pageCount: userMessageCount,
    };
  } else {
    return {
      messages: fullMessageList,
      pageCount: 0,
    };
  }
};
