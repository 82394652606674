import classNames from "classnames";
import styles from "./styles.module.css";
import { AppSwitch } from "../../../../shared/components/AppSwitch";

interface Props {
  className?: string;
  functionName: string;
  isActive: boolean;
  handleSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AssistantFunctionBlock: React.FC<Props> = ({
  className,
  functionName,
  isActive,
  handleSwitchChange,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <p className={styles.name}>{functionName}</p>
      <AppSwitch checked={isActive} onChange={handleSwitchChange} />
    </div>
  );
};
