import classNames from "classnames";
import styles from "./styles.module.css";
import { SmallXIcon } from "../../icons/SmallXIcon";

interface Props {
  className?: string;
  fileName: string;
  onDelete?: () => void;
}

export const AppFileTag: React.FC<Props> = ({
  className,
  fileName,
  onDelete,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <p>{fileName}</p>
      {!!onDelete && (
        <button onClick={onDelete} className={styles.button}>
          <SmallXIcon />
        </button>
      )}
    </div>
  );
};
