import { Pagination, PaginationProps } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props extends PaginationProps {
  className?: string;
}

export const AppPagination: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Pagination
      className={classNames(styles.container, className)}
      classes={{
        ul: styles.list,
      }}
      sx={{
        "& .Mui-selected": {
          backgroundColor: "#59c69e !important",
          color: "white",
          "&:hover": {
            backgroundColor: "#59c69e !important",
          },
        },
      }}
      count={10}
      shape="rounded"
      {...props}
    />
  );
};
