import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  type: TypeTextType;
}

export type TypeTextType = "expired" | "in_progress" | "completed";

export const typeTextArray = [
  {
    type: "expired",
    text: "Устарела",
  },
  {
    type: "in_progress",
    text: "В обработке",
  },
  {
    type: "completed",
    text: "Готова",
  },
];

export const AppInputStatus: React.FC<Props> = ({ className, type }) => {
  return (
    <div className={classNames(styles.status, styles[type], className)}>
      {typeTextArray.find((item) => item.type === type)?.text}
    </div>
  );
};
