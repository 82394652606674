import type { AppIconProps } from "../IconTypes";

export const ClearIcon: React.FC<AppIconProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width || "17"}
      height={height || "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 8.6748L14.3334 14H12.3334L10.3334 10.6748V14H2.33337L3.33337 8.6748M6.33337 10.6748V14M7.33337 2.1748V5.1748L2.33337 5.23077V8H14.3334V5.23077L9.33337 5.1748V2.1748H7.33337Z"
        stroke="#1D9F81"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
