import { CircularProgress, CircularProgressProps } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";

type Props = CircularProgressProps & {
  className?: string;
  small?: boolean;
  big?: boolean;
};

export const AppLoading: React.FC<Props> = ({ className, small, big }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <CircularProgress
        className={classNames(styles.loading, className)}
        size={small ? 30 : big ? 50 : 40}
      />
    </div>
  );
};
