import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import { AppButton } from "../../../shared/components/AppButton";
import { ClearIcon } from "../../../shared/icons/ClearIcon";
import { AppChatContainer } from "../../../shared/components/AppChatContainer";
import { AppInputBase } from "../../../shared/components/AppInputBase";
import {
  CompFullMessageListType,
  CompMessageType,
} from "../../../services/api/methodsTypes";
import {
  AssistAttachedFileType,
  LocalAssistantMessage,
} from "../../../pages/AssistantPage";
import { AssistAttachedFile } from "./AssistAttachedFile";
import { AppIconButton } from "../../../shared/components/AppIconButton";
import { PaperclipIcon } from "../../../shared/icons/PaperclipIcon";
import { SendIcon } from "../../../shared/icons/SendIcon";
import classNames from "classnames";
import styles from "./styles.module.css";
import { AppFileTag } from "../../../shared/components/AppFileTag";
import { AppLoading } from "../../../shared/components/AppLoading";

interface Props {
  className?: string;
  userMessage: string;
  setUserMessage: (value: string) => void;
  handleSend: () => void;
  handleFileUpload: (e: any) => void;
  isSendDisabled: boolean;
  assistantMode: boolean;
  messagePageCount: number;
  selectedPage: number;
  displayedMessages: LocalAssistantMessage[];
  attachedFile: AssistAttachedFileType | null;
  setAttachedFile: (value: AssistAttachedFileType | null) => void;
  handlePageChange: (value: number) => void;
  handleClearChat: () => void;
  handleAssistantModeChange: (value: string) => void;
  isFileLoading: boolean;
  customLoadingMessage: string | null;
}

export const AssistChat: React.FC<Props> = ({
  className,
  userMessage,
  setUserMessage,
  handleSend,
  handleFileUpload,
  isSendDisabled,
  assistantMode,
  messagePageCount,
  selectedPage,
  displayedMessages,
  attachedFile,
  setAttachedFile,
  handlePageChange,
  handleClearChat,
  handleAssistantModeChange,
  isFileLoading,
  customLoadingMessage,
}) => {
  const handleSendClick = () => {
    if (!isSendDisabled && !isFileLoading && userMessage) handleSend();
  };

  const handleSendKeyDown = (event: any) => {
    if (event.key === "Enter" && event.ctrlKey) {
      event.preventDefault();
      handleSendClick();
    }
  };

  const handleFileClick = (e: any) => {
    handleFileUpload(e);
  };

  return (
    <div className={classNames(styles.layout, className)}>
      <AppUnderlinedTitle
        className={styles.title}
        radio
        radioValue={assistantMode ? "assistant" : "chat"}
        setRadioValue={handleAssistantModeChange}
        selectedPage={selectedPage}
        count={messagePageCount}
        handlePageChange={handlePageChange}
      >
        <AppButton
          className={classNames(styles.clear, {
            [styles.disabled]: isSendDisabled,
          })}
          small
          onClick={handleClearChat}
          buttonType="text"
          icon={<ClearIcon />}
          disabled={isSendDisabled}
        >
          Очистить
        </AppButton>
      </AppUnderlinedTitle>
      <AppChatContainer
        chatClassName={styles.chat}
        assistantMode={assistantMode}
        displayedMessages={displayedMessages}
        isMessagesLoading={isSendDisabled}
        customLoadingMessage={customLoadingMessage}
      >
        <>
          <div className={styles["file-container"]}>
            {isFileLoading && <AppLoading small className={styles.loading} />}
            {attachedFile && (
              <AppFileTag
                fileName={attachedFile.name}
                onDelete={() => setAttachedFile(null)}
              />
            )}
          </div>
          <div className={styles["assist-bottom-container"]}>
            <AppIconButton
              component="label"
              onClick={(e: any) => {
                e.target.value = null;
              }}
              className={styles["chat-button"]}
              disabled={isSendDisabled || isFileLoading}
            >
              <input
                className={styles["upload-input"]}
                type="file"
                onChange={handleFileClick}
              />
              <PaperclipIcon className={styles.icon} />
            </AppIconButton>
            <AppInputBase
              small
              inputValue={userMessage}
              setInputValue={setUserMessage}
              onKeyDown={handleSendKeyDown}
              className={styles.input}
              rows={4}
              placeholder="Введите сообщение"
            />
            <AppIconButton
              onClick={handleSendClick}
              className={styles["chat-button"]}
              disabled={isSendDisabled || isFileLoading}
            >
              <SendIcon className={styles.icon} />
            </AppIconButton>
          </div>
        </>
      </AppChatContainer>
    </div>
  );
};
