import { useEffect, useState } from "react";
import { AppSidebar } from "../../shared/components/AppSidebar";
import { DatabaseMain } from "../../common/Database/DatabaseMain";
import { DatabaseFileView } from "../../common/Database/DatabaseFileView";
import {
  createStore,
  deleteFile,
  deleteStore,
  getFiles,
  getStoreFiles,
  getStores,
} from "../../services/api/methods";
import { AppNewFileDialog } from "../../shared/components/AppDialog/AppNewFileDialog";
import {
  AppDeleteDialog,
  defaultDeleteInfo,
  DeleteInfo,
} from "../../shared/components/AppDialog/AppDeleteDialog";
import { StoreData } from "../../services/api/methodsTypes";
import styles from "./styles.module.css";
import { ResizableWrapper } from "../../shared/components/ResizableWrapper";

export type DialogType = "file" | "store";

export type ShortFileData = {
  id: string;
  name: string;
};

export type StoreDataWithFiles = StoreData & {
  files?: ShortFileData[];
};

export const DatabasePage = () => {
  const [storeData, setStoreData] = useState<StoreDataWithFiles[]>([]);

  const [storeDataLoading, setStoreDataLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const [selectedSidebarItem, setSelectedSidebarItem] = useState<string | null>(
    null
  );
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] =
    useState<DeleteInfo>(defaultDeleteInfo);

  const updateStoreData = (): Promise<StoreDataWithFiles[]> => {
    return new Promise<StoreDataWithFiles[]>((resolve) => {
      setStoreDataLoading(true);

      Promise.all([getStores(), getFiles()])
        .then(([storesResponse, allFilesResponse]) => {
          const allFileList = allFilesResponse.data;

          const newStoreDataPromises = storesResponse.data.map(
            (store: StoreDataWithFiles) => {
              return getStoreFiles(store.id).then((res) => {
                const fileList: ShortFileData[] = res.data.map((file) => ({
                  id: file.id,
                  name:
                    allFileList.find((item) => item.id === file.id)?.filename ||
                    "",
                }));
                return { ...store, files: fileList };
              });
            }
          );

          // Ждем заполнение массива newStoreData
          Promise.all(newStoreDataPromises)
            .then((newStoreData) => {
              resolve(newStoreData);
            })
            .catch(console.log);
        })
        .catch(console.log);
    });
  };

  // Загружаем данные на рендере
  useEffect(() => {
    updateStoreData()
      .then((res) => {
        setStoreData(res);
        setSelectedSidebarItem(res[0].id);
      })
      .finally(() => {
        setStoreDataLoading(false);
      });
  }, []);

  const handleDialogClose = (
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setDialogOpen(false);
    const timerId = setTimeout(() => {
      setDeleteDialogInfo(defaultDeleteInfo);
    }, 300);

    return () => clearTimeout(timerId);
  };

  const handleDeleteDialogOpen = (id: string, dialogType: DialogType) => {
    let name = "";
    if (dialogType === "file") {
      // Поиск имени файла в storeData
      name =
        storeData
          .find((item) => item.id === selectedSidebarItem)
          ?.files?.find((item) => item.id === id)?.name || "";
    }
    if (dialogType === "store") {
      name = storeData.find((item) => item.id === id)?.name || "";
    }
    setDeleteDialogInfo({ id, name, type: dialogType });
    setDeleteDialogOpen(true);
  };

  const handleCreateStore = () => {
    const postObj = { name: "База Знаний " + (storeData.length + 1) };
    setStoreDataLoading(true);
    createStore(postObj)
      .then((newStore: StoreDataWithFiles) => {
        const newStoreData = storeData;
        newStoreData.unshift(newStore);
        setStoreData(newStoreData);
        return newStore;
      })
      .then((newStore) => {
        setSelectedSidebarItem(newStore.id);
      })
      .catch(console.error)
      .finally(() => {
        setStoreDataLoading(false);
      });
  };

  const handleDeleteStore = (id: string) => {
    const deletedItem = storeData.find((item) => item.id === id);
    const newStoreData = storeData.filter((item) => item.id !== id);
    setStoreData(newStoreData);
    if (selectedSidebarItem === id) {
      setSelectedSidebarItem(newStoreData[0].id);
    }

    if (deletedItem && deletedItem.files?.length) {
      deletedItem.files.forEach((file) => {
        deleteFile(file.id).catch(console.error);
      });
    }

    deleteStore(id).catch(console.error);
    setDeleteDialogOpen(false);
  };

  const handleDeleteFile = (id: string) => {
    const newStoreData = storeData.map((item) => {
      if (item.id === selectedSidebarItem) {
        return {
          ...item,
          files: item.files?.filter((item) => item.id !== id),
        };
      }
      return item;
    });
    setStoreData(newStoreData);

    deleteFile(id).catch(console.error);
    setDeleteDialogOpen(false);
  };

  return (
    <div className={styles["page-layout"]}>
      <AppSidebar
        addButton
        onAdd={handleCreateStore}
        menuItems={storeData}
        defaultName="База Знаний"
        onSelect={setSelectedSidebarItem}
        selectedId={selectedSidebarItem}
        isLoading={storeDataLoading}
        isSendDisabled={isFileLoading}
      />
      {selectedSidebarItem && (
        <DatabaseMain
          storeData={storeData}
          setStoreData={setStoreData}
          selectedSidebarItem={selectedSidebarItem}
          setAddDialogOpen={setAddDialogOpen}
          handleDeleteDialogOpen={handleDeleteDialogOpen}
          isFileLoading={isFileLoading}
          setIsFileLoading={setIsFileLoading}
        />
        //<DatabaseFileView />
      )}
      <AppNewFileDialog
        dialogOpen={addDialogOpen}
        handleDialogClose={() => handleDialogClose(setAddDialogOpen)}
      />
      <AppDeleteDialog
        dialogOpen={deleteDialogOpen}
        handleDialogClose={() => handleDialogClose(setDeleteDialogOpen)}
        deletingInfo={deleteDialogInfo}
        onDelete={() => {
          if (deleteDialogInfo.type === "store") {
            handleDeleteStore(selectedSidebarItem || "");
          }
          if (deleteDialogInfo.type === "file") {
            handleDeleteFile(deleteDialogInfo.id);
          }
        }}
      />
    </div>
  );
};
