import { CancelIcon } from "../../../shared/icons/CancelIcon";
import { ChartIcon } from "../../../shared/icons/ChartIcon";
import { CheckIcon } from "../../../shared/icons/CheckIcon";
import styles from "./styles.module.css";

interface Props {
  isModelSelected: boolean;
  isInputFilled: boolean;
}

export const Placeholder: React.FC<Props> = ({
  isModelSelected,
  isInputFilled,
}) => {
  return (
    <div className={styles.placeholder}>
      <div>
        <div className={styles["text-container"]}>
          {isModelSelected ? (
            <CheckIcon className={styles.icon} />
          ) : (
            <CancelIcon className={styles.icon} />
          )}
          <p className={styles.text}>Выберите модель для эмбеддинга текста</p>
        </div>
        <div className={styles["text-container"]}>
          {isInputFilled ? (
            <CheckIcon className={styles.icon} />
          ) : (
            <CancelIcon className={styles.icon} />
          )}
          <p className={styles.text}>Введите минимум 3 значения</p>
        </div>
      </div>
      <ChartIcon className={styles.chart} />
      <div>
        <h3 className={styles["links-title"]}>Учебные материалы</h3>
        <div className={styles.links}>
          <a target="_blank" href="https://habr.com/ru/articles/812431/">
            Про эмбеддинги на Habr
          </a>
        </div>
      </div>
    </div>
  );
};
