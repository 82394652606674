import type { AppIconProps } from "../IconTypes";

export const MinusIcon: React.FC<AppIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3125 11.25H7.6875C7.58437 11.25 7.5 11.3344 7.5 11.4375V12.5625C7.5 12.6656 7.58437 12.75 7.6875 12.75H16.3125C16.4156 12.75 16.5 12.6656 16.5 12.5625V11.4375C16.5 11.3344 16.4156 11.25 16.3125 11.25Z"
        fill="#002941"
        fillOpacity="0.15"
      />
      <path
        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
        fill="#002941"
        fillOpacity="0.15"
      />
      <path
        d="M2.25 12C2.25 6.61578 6.61578 2.25 12 2.25C17.3842 2.25 21.75 6.61578 21.75 12C21.75 17.3842 17.3842 21.75 12 21.75C6.61578 21.75 2.25 17.3842 2.25 12ZM2.53125 12C2.53125 17.2283 6.77172 21.4688 12 21.4688C17.2283 21.4688 21.4688 17.2283 21.4688 12C21.4688 6.77172 17.2283 2.53125 12 2.53125C6.77172 2.53125 2.53125 6.77172 2.53125 12Z"
        stroke="#002941"
        strokeOpacity="0.15"
        strokeWidth="1.5"
      />
    </svg>
  );
};
