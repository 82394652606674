import { Radio, RadioProps } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props extends RadioProps {
  className?: string;
}

export const AppRadio: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Radio
      className={classNames(styles.radio, className)}
      classes={{ checked: styles.checked }}
      {...props}
    />
  );
};
