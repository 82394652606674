import styles from "./styles.module.css";

const crossSvg = `
  <svg
    class="${styles["cross-icon"]}"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4L4 12M4 4L12 12"
      stroke="#59C69E"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

// TODO: Добавить глобальный ErrorContainer в pages/Page и добавлять ошибки туда для
// отображения нескольих ошибок одновременно списком
// TODO: Не показывать ошибки при разлогине, так и должно быть
export const showError = (message: string) => {
  const errorDiv = document.createElement("div");
  errorDiv.className = styles.error;

  // Текст
  const messagePar = document.createElement("p");
  messagePar.className = styles.message;
  messagePar.textContent = message;
  errorDiv.append(messagePar);

  // Кнопка закрытия
  const closeButton = document.createElement("button");
  closeButton.className = styles["close-button"];
  closeButton.innerHTML = crossSvg;
  closeButton.addEventListener("click", () => errorDiv.remove());
  errorDiv.append(closeButton);

  document.body.append(errorDiv);

  let appearTimeout: NodeJS.Timeout | null = null;
  let globalTimeout: NodeJS.Timeout | null = null;
  let removeTimeout: NodeJS.Timeout | null = null;

  appearTimeout = setTimeout(() => {
    errorDiv.style.opacity = "0.9";

    globalTimeout = setTimeout(() => {
      errorDiv.style.opacity = "0";
      removeTimeout = setTimeout(() => errorDiv.remove(), 1000);
    }, 10000);
  }, 1);

  const clearAllTimeouts = () => {
    if (appearTimeout) {
      clearTimeout(appearTimeout);
    }
    if (globalTimeout) {
      clearTimeout(globalTimeout);
    }
    if (removeTimeout) {
      clearTimeout(removeTimeout);
    }
  };

  return clearAllTimeouts;
};
