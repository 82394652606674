import type { AppIconProps } from "../IconTypes";

export const SendIcon: React.FC<AppIconProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66665 9.33333L14 2M6.66665 9.33333L8.99999 14C9.02924 14.0638 9.0762 14.1179 9.13529 14.1558C9.19438 14.1938 9.26311 14.2139 9.33332 14.2139C9.40353 14.2139 9.47227 14.1938 9.53135 14.1558C9.59044 14.1179 9.6374 14.0638 9.66665 14L14 2M6.66665 9.33333L1.99999 7C1.93616 6.97075 1.88207 6.92379 1.84415 6.8647C1.80623 6.80561 1.78607 6.73688 1.78607 6.66667C1.78607 6.59646 1.80623 6.52772 1.84415 6.46863C1.88207 6.40954 1.93616 6.36258 1.99999 6.33333L14 2"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
