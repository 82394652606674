import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  children?: string;
}

export const AppLabel: React.FC<Props> = ({ className, children }) => {
  return <p className={classNames(styles.container, className)}>{children}</p>;
};
