import { appRouterPaths } from "../../../services/navigation/urls";
import { EmbeddingsPage } from "../../../pages/EmbeddingsPage";
import { AppRouteProps } from "../types";

export const embeddingsPageRoute: AppRouteProps = {
  path: appRouterPaths.embeddings,
  element: <EmbeddingsPage />,
  link: "/embedding_models",
  name: "Эмбеддинги текстов",
};
