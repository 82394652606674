import { SelectChangeEvent } from "@mui/material";
import { AppIconButton } from "../../../../shared/components/AppIconButton";
import { AppSelect } from "../../../../shared/components/AppSelect";
import { AppSelectOption } from "../../../../shared/components/AppSelect/AppSelectOption";
import { MenuItem } from "../../../../shared/components/AppSidebar";
import { MinusIcon } from "../../../../shared/icons/MinusIcon";
import { SelectedUserListType } from "../../../../pages/ChatbotPage";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  userInfo: SelectedUserListType;
  userOptions: MenuItem[];
  onChange: (e: SelectChangeEvent<unknown>) => void;
  onDelete: (selectId: string) => void;
}

export const ChatbotUser: React.FC<Props> = ({
  className,
  userInfo,
  userOptions,
  onChange,
  onDelete,
}) => {
  return (
    <div className={styles["user-container"]}>
      <AppSelect
        className={className}
        value={userInfo.id}
        onChange={onChange}
        itemText={userInfo.name || ""}
      >
        {userOptions.map((item) => (
          <AppSelectOption key={item.id} value={item.id}>
            {item.name}
          </AppSelectOption>
        ))}
      </AppSelect>
      <AppIconButton
        className={styles["icon-button"]}
        onClick={() => onDelete(userInfo.selectId)}
      >
        <MinusIcon />
      </AppIconButton>
    </div>
  );
};
