import { AppChat } from "./AppChat";
import { LocalAssistantMessage } from "../../../pages/AssistantPage";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  chatClassName?: string;
  bottomClassName?: string;
  children?: React.ReactNode;
  assistantMode?: boolean;
  displayedMessages?: LocalAssistantMessage[];
  isMessagesLoading?: boolean;
  customLoadingMessage?: string | null;
}

export const AppChatContainer: React.FC<Props> = ({
  className,
  chatClassName,
  bottomClassName,
  children,
  assistantMode,
  displayedMessages,
  isMessagesLoading,
  customLoadingMessage,
}) => {
  return (
    <div className={classNames(styles["chat-container"], className)}>
      <AppChat
        className={chatClassName}
        assistantMode={assistantMode || false}
        displayedMessages={displayedMessages || []}
        isMessagesLoading={isMessagesLoading || false}
        customLoadingMessage={customLoadingMessage || null}
      />
      <div className={classNames(styles["bottom-container"], bottomClassName)}>
        {children}
      </div>
    </div>
  );
};
