import { useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from "@mui/material";
import { AppTable } from "../../../../shared/components/AppTable";
import classNames from "classnames";
import styles from "./styles.module.css";
import { AppButton } from "../../../../shared/components/AppButton";
import { AssistantInfoWithPlaceholder } from "../../../../pages/ChatbotPage";

interface Props {
  className?: string;
  dataRows: TableDataRow[];
  assistantList: AssistantInfoWithPlaceholder[];
  selectedAssistantId: string;
  setChangeAssistantDialogOpen: (isOpen: boolean) => void;
  setChangingChatId: React.Dispatch<React.SetStateAction<string | null>>;
}

const TABLE_HEADER: TableHeaderData[] = [
  {
    id: "chatId",
    label: "ID Чата",
    small: true,
  },
  {
    id: "chatName",
    label: "Имя Чата",
  },
  {
    id: "assistantId",
    label: "Имя Ассистента",
  },
];

export type Order = "asc" | "desc";

export type TableDataRow = {
  chatId: string;
  chatName: string;
  assistantId: string;
};

export type TableHeaderData = {
  id: keyof TableDataRow;
  small?: boolean;
  label: string;
};

export const ChatbotTable: React.FC<Props> = ({
  className,
  dataRows,
  assistantList,
  selectedAssistantId,
  setChangeAssistantDialogOpen,
  setChangingChatId,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof TableDataRow>("chatName");
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  const handleRequestSort = (property: keyof TableDataRow) => (event: any) => {
    event.preventDefault();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const localOnClick = (chatId: string) => {
    setChangingChatId(chatId);
    setChangeAssistantDialogOpen(true);
  };

  const SORTED_ROWS = dataRows.sort((a: TableDataRow, b: TableDataRow) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const getLocalAssistantName = (assistantId: string): string => {
    let assistantName = "";
    let localAssistant: AssistantInfoWithPlaceholder | undefined;

    localAssistant = assistantList.find(
      (assistant) => assistant.id === assistantId
    );
    if (assistantId === "def") {
      localAssistant = assistantList.find(
        (assistant) => assistant.id === selectedAssistantId
      );
    }
    if (localAssistant) {
      assistantName = localAssistant.name;
    }
    return assistantName;
  };

  return (
    <AppTable className={className}>
      <TableHead>
        <TableRow>
          {TABLE_HEADER.map((data) => (
            <TableCell
              key={data.id}
              className={classNames({ [styles["header-small"]]: data.small })}
            >
              <TableSortLabel
                active={orderBy === data.id}
                direction={orderBy === data.id ? order : "asc"}
                onClick={handleRequestSort(data.id)}
              >
                {data.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {SORTED_ROWS.map((row) => (
          <TableRow
            key={row.chatId}
            onMouseEnter={() => setHoveredRowId(row.chatId)}
            onMouseLeave={() => setHoveredRowId(null)}
            className={classNames(styles.row, {
              [styles["hovered-row"]]: hoveredRowId === row.chatId,
            })}
          >
            <TableCell className={styles.small}>{row.chatId}</TableCell>
            <TableCell>{row.chatName}</TableCell>
            <TableCell className={styles.assistant}>
              {getLocalAssistantName(row.assistantId)}

              <AppButton
                small
                buttonType="text"
                className={classNames(styles["change-button"], {
                  [styles.hidden]: hoveredRowId !== row.chatId,
                })}
                onClick={() => localOnClick(row.chatId)}
              >
                Сменить
              </AppButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </AppTable>
  );
};
