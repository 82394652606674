import { Select } from "@mui/material";
import classNames from "classnames";
import type { SelectProps } from "@mui/material";
import { ArrowIcon } from "../../icons/ArrowIcon";
import styles from "./styles.module.css";

type Props = SelectProps & {
  className?: string;
  placeholderText?: string;
  itemText?: string;
};

export const AppSelect: React.FC<Props> = ({
  value,
  className,
  placeholderText = "Выберите модель",
  itemText,
  disabled,
  ...props
}) => {
  return (
    <Select
      value={value}
      IconComponent={ArrowIcon}
      className={classNames(styles.select, className)}
      displayEmpty
      renderValue={() => {
        if (value === "")
          return <span className={styles.placeholder}>{placeholderText}</span>;
        else return itemText;
      }}
      MenuProps={{
        classes: { list: styles["menu-list"], paper: styles["menu-paper"] },
      }}
      classes={{
        select: `${styles["base-select"]} ${disabled ? styles.disabled : ""}`,
        icon: `${styles.icon} ${disabled ? styles.disabled : ""}`,
      }}
      sx={{
        ".MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderStyle: "none",
        },
      }}
      disabled={disabled}
      {...props}
    />
  );
};
