import { useEffect, useState } from "react";
import { Main } from "../../common/Embeddings/Main";
import { Sidebar } from "../../common/Embeddings/Sidebar";
import { Placeholder } from "../../common/Embeddings/Placeholder";
import { getModels, getVectors } from "../../services/api/methods";
import type {
  Model,
  PostObjType,
  Vectors,
} from "../../services/api/methodsTypes";
import styles from "./styles.module.css";

export const EmbeddingsPage = () => {
  const [models, setModels] = useState<Model[]>([]);
  const [vectors, setVectors] = useState<Vectors | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModelSelected, setIsModelSelected] = useState<boolean>(false);
  const [isInputFilled, setIsInputFilled] = useState<boolean>(false);

  useEffect(() => {
    getModels().then(setModels).catch(console.error);
  }, []);

  const handleModeling = (postObj: PostObjType) => {
    setIsLoading(true);

    getVectors(postObj)
      .then(setVectors)
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={styles.container}>
      <Sidebar
        models={models}
        onModeling={handleModeling}
        isLoading={isLoading}
        isInputFilled={isInputFilled}
        setIsModelSelected={setIsModelSelected}
        setIsInputFilled={setIsInputFilled}
      />
      {!vectors && !isLoading ? (
        <Placeholder
          isModelSelected={isModelSelected}
          isInputFilled={isInputFilled}
        />
      ) : (
        <Main vectors={vectors} isLoading={isLoading} />
      )}
    </div>
  );
};
