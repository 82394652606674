import { appRouterPaths } from "../../../services/navigation/urls";
import { ChatbotPage } from "../../../pages/ChatbotPage";
import { AppRouteProps } from "../types";

export const chatbotPageRoute: AppRouteProps = {
  path: appRouterPaths.chatbot,
  element: <ChatbotPage />,
  link: "/telegramm_bots",
  name: "Телеграмм-Бот",
};
