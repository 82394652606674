import { Slider, SliderProps } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";

type Props = SliderProps & {
  className?: string;
};

export const AppSlider: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Slider
      className={classNames(styles.slider, className)}
      classes={{
        thumb: styles.thumb,
        track: styles.track,
        rail: styles.rail,
        dragging: styles.dragging,
        active: styles.active,
        disabled: styles.disabled,
      }}
      {...props}
    />
  );
};
