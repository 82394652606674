import { IconButton } from "@mui/material";
import classNames from "classnames";
import type { IconButtonProps } from "@mui/material";
import styles from "./styles.module.css";

interface Props extends IconButtonProps {
  buttonType?: "contained" | "outlined" | "text";
  big?: boolean;
  warning?: boolean;
  disabled?: boolean;
}

export const AppIconButton: React.FC<Props> = ({
  children,
  className,
  buttonType = "text",
  big,
  warning,
  disabled,
  ...props
}) => {
  return (
    <IconButton
      className={classNames(
        styles.button,
        styles[buttonType],
        {
          [styles.big]: big,
          [styles.warning]: warning,
          [styles.disabled]: disabled,
        },
        className
      )}
      disabled={disabled}
      centerRipple={false}
      {...props}
    >
      {children}
    </IconButton>
  );
};
