import { StyledEngineProvider } from "@mui/material";
import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { ProfileContextProvider } from "../../services/profile";
import { LocalizationProvider } from "@mui/x-date-pickers/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ru } from "date-fns/locale/ru";

interface Props {
  children: ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  return (
    <ProfileContextProvider>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            {children}
          </LocalizationProvider>
        </BrowserRouter>
      </StyledEngineProvider>
    </ProfileContextProvider>
  );
};
