import { AppDialog } from "..";
import { AppButton } from "../../AppButton";
import { AppLabel } from "../../AppLabel";
import { AppInput } from "../../AppInput";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  dialogOpen: boolean;
  handleDialogClose: () => void;
}

export const AppNewFileDialog: React.FC<Props> = ({
  className,
  dialogOpen,
  handleDialogClose,
}) => {
  return (
    <AppDialog
      classes={{ paper: classNames(styles.dialog, className) }}
      open={dialogOpen}
    >
      <h1 className={styles["dialog-title"]}>Добавить файл</h1>
      <div className={styles["input-container"]}>
        <AppLabel className={styles.label}>Внутренний номер</AppLabel>
        <AppInput placeholder="--" />
      </div>
      <div className={classNames(styles["input-container"], styles["last"])}>
        <AppLabel className={styles.label}>Разделитель</AppLabel>
        <AppInput placeholder="--" />
      </div>

      <div className={styles["dialog-button-container"]}>
        <AppButton small buttonType="outlined" onClick={handleDialogClose}>
          Отмена
        </AppButton>
        <AppButton small buttonType="contained" disabled>
          Добавить
        </AppButton>
      </div>
    </AppDialog>
  );
};
