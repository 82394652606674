import axios from "axios";
import { getAccessToken } from "../utils";
import { APP_ACCESS_TOKEN_NAME, APP_REFRESH_TOKEN_NAME } from "../constants";

let APP_CLASSICATOR_TOOLS_API_URL = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  APP_CLASSICATOR_TOOLS_API_URL =
    process.env.REACT_APP_CLASSICATOR_TOOLS_URL || "/";
} else {
  APP_CLASSICATOR_TOOLS_API_URL =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_URL;
}

export let appClassificatorToolsApi = axios.create({
  baseURL: APP_CLASSICATOR_TOOLS_API_URL,
});

export const recreateAppClassificatorToolsApi = (
  setUserInfo: (value: null) => void
) => {
  appClassificatorToolsApi.interceptors.request.use((request) => {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
    }
    return request;
  });

  appClassificatorToolsApi.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem(APP_ACCESS_TOKEN_NAME);
        localStorage.removeItem(APP_REFRESH_TOKEN_NAME);
        setUserInfo(null);
      }
      return Promise.reject(error);
    }
  );
};
