import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Cell,
  ZAxis,
} from "recharts";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  cords: DataPoint[];
  isVisible?: boolean;
}

export interface DataPoint {
  x: number;
  y: number;
  label: string;
}

const COLORS = [
  "#52C41A",
  "#FF4D4F",
  "#9E1068",
  "#1677FF",
  "#9254DE",
  "#FA8C16",
];

export const AppChart: React.FC<Props> = ({ className, cords, isVisible }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height="auto"
      aspect={2}
      className={styles.container}
    >
      <ScatterChart className={classNames(styles.chart, className)}>
        <CartesianGrid strokeWidth={0.5} />
        <XAxis type="number" dataKey="x" domain={[0, 1]} tickCount={6} />
        <YAxis type="number" dataKey="y" domain={[0, 1]} tickCount={6} />
        <ZAxis range={[150]} />
        <Scatter data={isVisible ? cords : []} fill="#8884d8">
          {cords.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <LabelList
            dataKey="label"
            content={({ x, y, value }) => (
              <text
                x={(x as number) + 7}
                y={y}
                dy={35}
                textAnchor="middle"
                className={styles.label}
              >
                {value}
              </text>
            )}
          />
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
};
