import { InputBase, InputBaseProps } from "@mui/material";
import { AppIconButton } from "../AppIconButton";
import { SendIcon } from "../../icons/SendIcon";
import classNames from "classnames";
import styles from "./styles.module.css";
import { PaperclipIcon } from "../../icons/PaperclipIcon";

type Props = InputBaseProps & {
  className?: string;
  sendButton?: boolean;
  fileButton?: boolean;
  small?: boolean;
  innerClassName?: string;
  inputValue?: string;
  setInputValue?: (value: string) => void;
  onSend?: (value: string) => void;
  onFileClick?: (e: any) => void;
  isSendDisabled?: boolean;
};

export const AppInputBase: React.FC<Props> = ({
  className,
  sendButton,
  fileButton,
  small,
  innerClassName,
  inputValue,
  setInputValue,
  onSend,
  onFileClick,
  isSendDisabled,
  disabled,
  ...props
}) => {
  const handleInputChange = (event: any) => {
    setInputValue && setInputValue(event.target.value);
  };

  const handleSend = (e: any) => {
    if (!isSendDisabled && inputValue) onSend?.(inputValue);
  };

  const handleSendKeyDown = (event: any) => {
    if (event.key === "Enter" && event.ctrlKey) {
      event.preventDefault();
      handleSend(event);
    }
  };

  const handleFileClick = (e: any) => {
    onFileClick && onFileClick(e);
  };

  return (
    <div className={styles.container}>
      <InputBase
        value={inputValue}
        className={classNames(
          styles.input,
          { [styles.send]: sendButton },
          { [styles.disabled]: disabled },
          className
        )}
        fullWidth
        onChange={handleInputChange}
        onKeyDown={handleSendKeyDown}
        classes={{
          input: classNames(
            styles.inner,
            { [styles.small]: small },
            innerClassName
          ),
        }}
        multiline
        rows={4}
        disabled={disabled}
        {...props}
      />
      <div className={styles["button-container"]}>
        {fileButton && (
          <AppIconButton
            component="label"
            onClick={(e: any) => {
              e.target.value = null;
            }}
            className={classNames(styles["file-button"], {
              [styles.disabled]: disabled,
            })}
            disabled={isSendDisabled}
          >
            <input
              className={styles["upload-input"]}
              type="file"
              onChange={handleFileClick}
            />
            <PaperclipIcon className={styles.icon} />
          </AppIconButton>
        )}
        {sendButton && (
          <AppIconButton
            onClick={handleSend}
            className={classNames(styles["send-button"], {
              [styles.disabled]: isSendDisabled,
            })}
            disabled={isSendDisabled}
          >
            <SendIcon className={styles.icon} />
          </AppIconButton>
        )}
      </div>
    </div>
  );
};
