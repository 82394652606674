import type { FC, ReactNode } from "react";
import styles from "./styles.module.css";
import {
  PanelProps,
  PanelGroup,
  Panel,
  PanelResizeHandle,
} from "react-resizable-panels";
interface Props extends PanelProps {
  leftBlock: ReactNode;
  rightBlock: ReactNode;
  leftBlockSize: number;
  rightBlockSize: number;
}

export const ResizableWrapper: FC<Props> = ({
  leftBlock,
  rightBlock,
  leftBlockSize,
  rightBlockSize,
  ...props
}) => {
  return (
    <PanelGroup direction="horizontal">
      <Panel
        defaultSize={leftBlockSize}
        className={styles["resize-panel-left"]}
        {...props}
      >
        {leftBlock}
      </Panel>
      <PanelResizeHandle children={<div className={styles["resize"]}></div>} />
      <Panel
        defaultSize={rightBlockSize}
        className={styles["resize-panel-right"]}
        {...props}
      >
        {rightBlock}
      </Panel>
    </PanelGroup>
  );
};
