import { Input, InputProps } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";
import { AppInputStatus, TypeTextType } from "./AppInputStatus";

type Props = InputProps & {
  className?: string;
  inputClassName?: string;
  status?: TypeTextType;
  small?: boolean;
};

export const AppInput: React.FC<Props> = ({
  className,
  inputClassName,
  status,
  small,
  disabled,
  ...props
}) => {
  return (
    <Input
      fullWidth
      disableUnderline
      endAdornment={status && <AppInputStatus type={status} />}
      classes={{
        input: classNames(
          styles.input,
          {
            [styles.small]: small,
          },
          inputClassName
        ),
      }}
      className={classNames(
        styles.container,
        { [styles.disabled]: disabled },
        className
      )}
      disabled={disabled}
      {...props}
    />
  );
};
