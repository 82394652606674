import { useState } from "react";
import {
  CardInputValues,
  ClassLeftContainer,
} from "../../common/Classification/ClassLeftContainer";
import { classLeftInputValues } from "../../common/Classification/classLeftInputValues";
import {
  ClassInputValues,
  ClassRightContainer,
} from "../../common/Classification/ClassRightContainer";
import {
  classificatorFolderId,
  classificatorKey,
  classificatorModel,
} from "../../services/api/constants";
import { getClasses } from "../../services/api/methods";
import { PredictionObject } from "../../services/api/methodsTypes";
import styles from "./styles.module.css";

export const ClassificationPage = () => {
  const [leftData, setLeftData] =
    useState<CardInputValues[]>(classLeftInputValues);

  const [data, setData] = useState<PredictionObject | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const getRequestData = (rightData: ClassInputValues) => {
    const requestData = {
      prefix: rightData.instruction,
      labels: leftData
        .filter((card) => card.variant)
        .map((card) => {
          return {
            label: card.variant,
            description: card.descValue,
            samples: card.values.map((value) => value.value),
          };
        }),
      text_request: rightData.classification,
      folder_id: classificatorFolderId,
      api_key: classificatorKey,
      model: classificatorModel,
    };
    return requestData;
  };

  const handleClassification = (rightData: ClassInputValues) => {
    setIsLoading(true);

    const requestData = getRequestData(rightData);

    getClasses(requestData)
      .then(setData)
      .catch(console.error)
      .finally(() => {
        setIsFetched(true);
        setIsLoading(false);
      });
  };

  const getRequestBody = (rightData: ClassInputValues) => {
    const requestData = getRequestData(rightData);

    return JSON.stringify(requestData, null, "\t").replace(/\\n/g, " ");
  };

  return (
    <div className={styles["layout-container"]}>
      <ClassLeftContainer leftData={leftData} setLeftData={setLeftData} />

      <ClassRightContainer
        data={data}
        leftData={leftData}
        isLoading={isLoading}
        isFetched={isFetched}
        onClassification={handleClassification}
        requestBody={getRequestBody}
      />
    </div>
  );
};
