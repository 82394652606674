import { AppButton } from "../AppButton";
import classNames from "classnames";
import styles from "./styles.module.css";
import { AppRadioGroup } from "../AppRadioGroup";
import { AppRadioLabel } from "../AppRadioGroup/AppRadioLabel";
import { AppPagination } from "../AppPagination";

interface Props {
  className?: string;
  title?: string;
  buttonTitle?: boolean;
  radio?: boolean;
  buttonClassName?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  radioValue?: string;
  setRadioValue?: (e: any) => void;
  selectedPage?: number;
  handlePageChange?: (value: any) => void;
  count?: number;
}

export const AppUnderlinedTitle: React.FC<Props> = ({
  className,
  title,
  buttonTitle,
  radio,
  buttonClassName,
  children,
  disabled,
  onClick,
  radioValue = "chat",
  setRadioValue,
  selectedPage,
  handlePageChange,
  count,
}) => {
  return (
    <div
      className={classNames(
        styles.title,
        { [styles["radio-title"]]: radio },
        className
      )}
    >
      {buttonTitle ? (
        <AppButton
          className={buttonClassName}
          onClick={onClick}
          buttonType="text"
          disabled={disabled}
        >
          {title}
        </AppButton>
      ) : radio ? (
        <AppRadioGroup
          row
          value={radioValue}
          onChange={(e) => setRadioValue?.(e.target.value)}
        >
          <AppRadioLabel value="chat" label="Чат" />
          <AppRadioLabel value="assistant" label="Ассистент" />
        </AppRadioGroup>
      ) : (
        <span>{title || ""}</span>
      )}
      <div className={styles.absolute}>
        {radioValue === "assistant" && (
          <AppPagination
            className={styles.pagination}
            page={selectedPage}
            count={count}
            onChange={(e, value) => handlePageChange?.(value)}
            siblingCount={0}
            boundaryCount={1}
          />
        )}
        {children}
      </div>
    </div>
  );
};
