import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import { AppRadio } from "../AppRadio";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props extends Omit<FormControlLabelProps, "control"> {
  className?: string;
  label: string;
}

export const AppRadioLabel: React.FC<Props> = ({
  className,
  label,
  ...props
}) => {
  return (
    <FormControlLabel
      className={classNames(styles.label, className)}
      classes={{ label: styles["label-text"] }}
      control={<AppRadio />}
      label={label}
      {...props}
    />
  );
};
