import { useEffect, useState } from "react";
import { CompChat } from "../../common/Comparison/CompChat";
import { AppButton } from "../../shared/components/AppButton";
import { AppInputBase } from "../../shared/components/AppInputBase";
import { SendIcon } from "../../shared/icons/SendIcon";
import { CompDialog } from "../../common/Comparison/CompDialog";
import { deleteAssistant, getAssistants } from "../../services/api/methods";
import { CompModel } from "../../services/api/methodsTypes";
import styles from "./styles.module.css";
import { getTransformedModels } from "../../shared/utils/getTransformedModels";

export type SelectedValue = {
  chatNumber: number;
  value: string;
};

const startingInputValues = {
  instructions: "",
  requestText: "",
};

const startingModelValues = [
  { chatNumber: 0, value: "" },
  { chatNumber: 1, value: "" },
  { chatNumber: 2, value: "" },
];

export const ComparasionPage = () => {
  const [instructions, setInstructions] = useState(
    startingInputValues.instructions
  );
  const [requestText, setRequestText] = useState(
    startingInputValues.requestText
  );
  const [models, setModels] = useState<CompModel[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] =
    useState<SelectedValue[]>(startingModelValues);
  const [passedChatNumber, setPassedChatNumber] = useState(0);
  const [sendRequestSwitch, setSendRequestSwitch] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [disableCounter, setDisableCounter] = useState(0);

  // Fetching models
  useEffect(() => {
    getTransformedModels()
      .then((res) => setModels(res.models))
      .catch(console.error);

    // Очистка ассистентов без метадаты
    return () => {
      getAssistants().then((res) => {
        res.data.map((item) => {
          if (!item.metadata && item.id) {
            deleteAssistant(item.id);
          }
        });
      });
    };
  }, []);

  const handleSelectValue = (tempValue: string, chatNumber: number) => {
    setIsOpen(false);
    setSelectedValues((prev) =>
      prev.map((item) => {
        if (item.chatNumber === chatNumber) {
          return { ...item, value: tempValue };
        }
        return item;
      })
    );
  };

  const handleSendRequest = () => {
    setDisableCounter(0);
    setSendRequestSwitch(!sendRequestSwitch);
  };

  useEffect(() => {
    let neededDisableCounter = 0;

    selectedValues.map((item) => {
      if (item.value !== "") {
        neededDisableCounter++;
      }
    });

    if (neededDisableCounter === disableCounter) {
      setIsSendDisabled(false);
      return;
    }
  }, [disableCounter]);

  const handleDialogOpen = (chatNumber: number) => {
    setPassedChatNumber(chatNumber);
    setIsOpen(true);
  };

  const handleRequestKeyDown = (event: any) => {
    if (event.key === "Enter" && event.ctrlKey) {
      event.preventDefault();

      if (!isSendDisabled) handleSendRequest();
    }
  };

  return (
    <div className={styles["layout-container"]}>
      <AppInputBase
        small
        onBlur={(e) => setInstructions(e.target.value)}
        placeholder="Инструкция"
        rows={5}
      />
      <div className={styles["chat-container"]}>
        {selectedValues.map((value) => (
          <CompChat
            key={value.chatNumber}
            instructions={instructions}
            requestText={requestText}
            models={models}
            sendRequestSwitch={sendRequestSwitch}
            onDialogOpen={handleDialogOpen}
            setDisableCounter={setDisableCounter}
            setRequestText={setRequestText}
            setIsSendDisabled={setIsSendDisabled}
            selectedValues={selectedValues}
            chatNumber={value.chatNumber}
          />
        ))}
      </div>
      <div className={styles["request-container"]}>
        <AppInputBase
          small
          value={requestText}
          onChange={(e) => setRequestText(e.target.value)}
          onKeyDown={handleRequestKeyDown}
          placeholder="Запрос"
          className={styles.input}
          rows={5}
        />
        <AppButton
          className={styles["send-btn"]}
          buttonType="contained"
          icon={<SendIcon />}
          small
          onClick={handleSendRequest}
          disabled={isSendDisabled}
        >
          Отправить
        </AppButton>
      </div>
      <CompDialog
        models={models}
        selectedValues={selectedValues}
        chatNumber={passedChatNumber}
        open={isOpen}
        setIsOpen={setIsOpen}
        onSelectValue={handleSelectValue}
      />
    </div>
  );
};
