import { DateField, DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { CalendarIcon } from "../../icons/CalendarIcon";
import classNames from "classnames";
import styles from "./styles.module.css";

type Props = DatePickerProps<Date> & {
  className?: string;
};

export const AppDatePicker: React.FC<Props> = ({ className, ...props }) => {
  return (
    <DatePicker
      className={classNames(styles.root, className)}
      slotProps={{
        textField: {
          placeholder: "Выберите дату",
        },
        desktopPaper: {
          className: styles.paper,
        },
        calendarHeader: {
          className: styles["calendar-header"],
        },
        day: {
          className: styles["day-button"],
          sx: {
            "&:hover": {
              backgroundColor: "var(--primary-light)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "var(--primary-light) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "var(--primary-color) !important",
              "&:hover": {
                backgroundColor: "var(--primary-color)",
              },
            },
          },
        },
        yearButton: {
          sx: {
            "&:hover": {
              backgroundColor: "var(--primary-light)",
            },
            "&.Mui-selected": {
              backgroundColor: "var(--primary-color) !important",
              "&:hover": {
                backgroundColor: "var(--primary-color)",
              },
            },
          },
        },
      }}
      slots={{
        openPickerIcon: () => <CalendarIcon />,
        // field: () => <DateField clearable helperText="test" />,
      }}
      {...props}
    />
  );
};
