import type { AppIconProps } from "../IconTypes";

export const EyeInvisibleIcon: React.FC<AppIconProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width || "17"}
      height={height || "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2219 7.5972C14.6726 6.44002 14.019 5.49627 13.261 4.76595L12.466 5.56095C13.1142 6.18048 13.6789 6.99095 14.1672 8.00033C12.8672 10.691 11.0219 11.9691 8.50003 11.9691C7.74305 11.9691 7.04581 11.8525 6.40831 11.6194L5.5469 12.4808C6.43232 12.8897 7.4167 13.0941 8.50003 13.0941C11.5032 13.0941 13.7438 11.53 15.2219 8.40189C15.2813 8.27609 15.3121 8.13868 15.3121 7.99955C15.3121 7.86041 15.2813 7.72301 15.2219 7.5972ZM14.2286 2.5872L13.5625 1.92033C13.5509 1.90871 13.5371 1.89949 13.522 1.8932C13.5068 1.8869 13.4905 1.88367 13.4741 1.88367C13.4577 1.88367 13.4414 1.8869 13.4262 1.8932C13.411 1.89949 13.3973 1.90871 13.3857 1.92033L11.6767 3.62845C10.7346 3.1472 9.67565 2.90658 8.50003 2.90658C5.4969 2.90658 3.25628 4.47064 1.77815 7.59877C1.71874 7.72457 1.68793 7.86198 1.68793 8.00111C1.68793 8.14024 1.71874 8.27765 1.77815 8.40345C2.36868 9.6472 3.07961 10.6441 3.91097 11.3942L2.25753 13.0472C2.2341 13.0706 2.22095 13.1024 2.22095 13.1356C2.22095 13.1687 2.2341 13.2005 2.25753 13.2239L2.92456 13.891C2.948 13.9144 2.97978 13.9275 3.01292 13.9275C3.04606 13.9275 3.07784 13.9144 3.10128 13.891L14.2286 2.76408C14.2402 2.75247 14.2495 2.73868 14.2558 2.72351C14.262 2.70833 14.2653 2.69207 14.2653 2.67564C14.2653 2.65921 14.262 2.64295 14.2558 2.62777C14.2495 2.6126 14.2402 2.59881 14.2286 2.5872ZM2.83284 8.00033C4.1344 5.3097 5.97972 4.03158 8.50003 4.03158C9.35222 4.03158 10.1271 4.17783 10.83 4.47517L9.73159 5.57361C9.21139 5.29605 8.61574 5.19304 8.03254 5.27977C7.44933 5.36651 6.90944 5.63839 6.49252 6.05532C6.07559 6.47224 5.80371 7.01213 5.71698 7.59534C5.63024 8.17854 5.73325 8.77418 6.01081 9.29439L4.70737 10.5978C3.98597 9.96111 3.36409 9.09845 2.83284 8.00033ZM6.68753 8.00033C6.6878 7.7248 6.75306 7.45323 6.87801 7.20767C7.00296 6.9621 7.18408 6.74948 7.40664 6.58706C7.6292 6.42464 7.88694 6.31702 8.15891 6.27293C8.43089 6.22883 8.70943 6.24952 8.9719 6.3333L6.7705 8.5347C6.71532 8.36195 6.68733 8.18168 6.68753 8.00033Z"
        fill="#39A37C"
      />
      <path
        d="M8.43767 9.75019C8.38361 9.75019 8.33033 9.74769 8.27751 9.74285L7.4522 10.5682C7.94859 10.7583 8.48942 10.8005 9.00931 10.6898C9.5292 10.5791 10.0059 10.3201 10.3817 9.94427C10.7576 9.56841 11.0165 9.09172 11.1273 8.57184C11.238 8.05195 11.1957 7.51112 11.0056 7.01473L10.1803 7.84004C10.1852 7.89285 10.1877 7.94613 10.1877 8.0002C10.1878 8.23004 10.1426 8.45766 10.0547 8.67004C9.9668 8.88241 9.83791 9.07538 9.67538 9.23791C9.51285 9.40043 9.31989 9.52933 9.10751 9.61723C8.89514 9.70513 8.66752 9.75031 8.43767 9.75019Z"
        fill="#39A37C"
      />
    </svg>
  );
};
