import type { AppIconProps } from "../IconTypes";

export const LoginIcon: React.FC<AppIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9C1 10.0506 1.20693 11.0909 1.60896 12.0615C2.011 13.0321 2.60028 13.914 3.34315 14.6569C4.08601 15.3997 4.96793 15.989 5.93853 16.391C6.90914 16.7931 7.94943 17 9 17C10.0506 17 11.0909 16.7931 12.0615 16.391C13.0321 15.989 13.914 15.3997 14.6569 14.6569C15.3997 13.914 15.989 13.0321 16.391 12.0615C16.7931 11.0909 17 10.0506 17 9C17 7.94943 16.7931 6.90914 16.391 5.93853C15.989 4.96793 15.3997 4.08601 14.6569 3.34315C13.914 2.60028 13.0321 2.011 12.0615 1.60896C11.0909 1.20693 10.0506 1 9 1C7.94943 1 6.90914 1.20693 5.93853 1.60896C4.96793 2.011 4.08601 2.60028 3.34315 3.34315C2.60028 4.08601 2.011 4.96793 1.60896 5.93853C1.20693 6.90914 1 7.94943 1 9Z"
        fill="#59C69E"
      />
      <path
        d="M6.33333 7.22222C6.33333 7.92947 6.61428 8.60774 7.11438 9.10784C7.61448 9.60794 8.29276 9.88889 9 9.88889C9.70724 9.88889 10.3855 9.60794 10.8856 9.10784C11.3857 8.60774 11.6667 7.92947 11.6667 7.22222C11.6667 6.51498 11.3857 5.8367 10.8856 5.3366C10.3855 4.83651 9.70724 4.55556 9 4.55556C8.29276 4.55556 7.61448 4.83651 7.11438 5.3366C6.61428 5.8367 6.33333 6.51498 6.33333 7.22222Z"
        fill="white"
      />
      <path
        d="M3.816 15.088C4.03601 14.3558 4.48619 13.7139 5.09977 13.2578C5.71335 12.8016 6.45764 12.5553 7.22222 12.5556H9H10.7778C11.5433 12.5553 12.2885 12.8021 12.9026 13.2594C13.5166 13.7166 13.9666 14.3598 14.1858 15.0933"
        fill="#59C69E"
      />
      <path
        d="M3.816 15.088C4.03601 14.3558 4.48619 13.7139 5.09977 13.2578C5.71335 12.8016 6.45764 12.5553 7.22222 12.5556H9H10.7778C11.5433 12.5553 12.2885 12.8021 12.9026 13.2594C13.5166 13.7166 13.9666 14.3598 14.1858 15.0933M1 9C1 10.0506 1.20693 11.0909 1.60896 12.0615C2.011 13.0321 2.60028 13.914 3.34315 14.6569C4.08601 15.3997 4.96793 15.989 5.93853 16.391C6.90914 16.7931 7.94943 17 9 17C10.0506 17 11.0909 16.7931 12.0615 16.391C13.0321 15.989 13.914 15.3997 14.6569 14.6569C15.3997 13.914 15.989 13.0321 16.391 12.0615C16.7931 11.0909 17 10.0506 17 9C17 7.94943 16.7931 6.90914 16.391 5.93853C15.989 4.96793 15.3997 4.08601 14.6569 3.34315C13.914 2.60028 13.0321 2.011 12.0615 1.60896C11.0909 1.20693 10.0506 1 9 1C7.94943 1 6.90914 1.20693 5.93853 1.60896C4.96793 2.011 4.08601 2.60028 3.34315 3.34315C2.60028 4.08601 2.011 4.96793 1.60896 5.93853C1.20693 6.90914 1 7.94943 1 9ZM6.33333 7.22222C6.33333 7.92947 6.61428 8.60774 7.11438 9.10784C7.61448 9.60794 8.29276 9.88889 9 9.88889C9.70724 9.88889 10.3855 9.60794 10.8856 9.10784C11.3857 8.60774 11.6667 7.92947 11.6667 7.22222C11.6667 6.51498 11.3857 5.8367 10.8856 5.3366C10.3855 4.83651 9.70724 4.55556 9 4.55556C8.29276 4.55556 7.61448 4.83651 7.11438 5.3366C6.61428 5.8367 6.33333 6.51498 6.33333 7.22222Z"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
