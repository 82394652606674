import { getCompModels } from "../../services/api/methods";
import {
  CompModel,
  ModelsArray,
  OpenAiModelArray,
} from "../../services/api/methodsTypes";

// Если есть propery object, то нам пришел OpenAiModelArray
// Если нет, то нам пришел CompModel
const isOpenAiModelArray = (res: any): res is OpenAiModelArray => {
  return res.hasOwnProperty("object") && res.object === "list";
};

export const getTransformedModels = (): Promise<ModelsArray> => {
  return new Promise<ModelsArray>((resolve) => {
    getCompModels().then((res) => {
      if (isOpenAiModelArray(res)) {
        const newModelArray: CompModel[] = res.data.map((model) => ({
          friendly_name: model.id,
          model_name: model.id,
        }));

        const newPostArray: ModelsArray = {
          models: newModelArray,
        };

        resolve(newPostArray);
      } else {
        resolve(res);
      }
    });
  });
};
