import { appRouterPaths } from "../../../services/navigation/urls";
import { DatabasePage } from "../../../pages/DatabasePage";
import { AppRouteProps } from "../types";

export const databasePageRoute: AppRouteProps = {
  path: appRouterPaths.database,
  element: <DatabasePage />,
  link: "/knowledge_base",
  name: "База знаний",
};
