import type { AppIconProps } from "../IconTypes";

export const LogoutIcon: React.FC<AppIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 5.33341V4.00008C9.33333 3.64646 9.19286 3.30732 8.94281 3.05727C8.69276 2.80722 8.35362 2.66675 8 2.66675H3.33333C2.97971 2.66675 2.64057 2.80722 2.39052 3.05727C2.14048 3.30732 2 3.64646 2 4.00008V12.0001C2 12.3537 2.14048 12.6928 2.39052 12.9429C2.64057 13.1929 2.97971 13.3334 3.33333 13.3334H8C8.35362 13.3334 8.69276 13.1929 8.94281 12.9429C9.19286 12.6928 9.33333 12.3537 9.33333 12.0001V10.6667M4.66667 8.00008H14M14 8.00008L12 6.00008M14 8.00008L12 10.0001"
        stroke="#59C69E"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
