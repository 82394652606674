import { AppProvider } from "./providers";
import { AppRouting } from "./routing";
import {useEffect} from "react";
import axios from "axios";
import {login} from "../services/api/methods";

export const App = () => {
  return (
    <AppProvider>
      <AppRouting />
    </AppProvider>
  );
};
