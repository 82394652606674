import { AppButton } from "../../../../shared/components/AppButton";
import { AppInputBase } from "../../../../shared/components/AppInputBase";
import { ClassInputContainer } from "./ClassInputContainer";
import { PlusIcon } from "../../../../shared/icons/PlusIcon";
import { TrashIcon } from "../../../../shared/icons/TrashIcon";
import type { CardInputValues, ChangedInput } from "..";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  card: CardInputValues;
  onAddInput: (cardId: string) => void;
  onDeleteCard: (cardId: string) => void;
  onDeleteInput: (cardId: string, inputId: string) => void;
  onInputChange: (
    changedInput: ChangedInput,
    cardId: string,
    inputId: string,
    value: string
  ) => void;
}

export const ClassReviewCard: React.FC<Props> = ({
  className,
  card,
  onAddInput,
  onDeleteCard,
  onDeleteInput,
  onInputChange,
}) => {
  return (
    <div className={classNames(styles.content, className)}>
      <div className={styles["left-container"]}>
        <AppInputBase
          className={styles.select}
          small
          rows={undefined}
          placeholder="Вариант"
          onChange={(e) =>
            onInputChange("variant", card.cardId, "", e.target.value)
          }
        />
        <AppButton
          className={styles.button}
          icon={<TrashIcon />}
          buttonType="text"
          onClick={() => onDeleteCard(card.cardId)}
        >
          Удалить
        </AppButton>
      </div>
      <div className={styles["right-container"]}>
        <AppInputBase
          className={classNames(styles.input, styles["top-input"])}
          small
          rows={undefined}
          placeholder="Описание"
          onChange={(e) =>
            onInputChange("descValue", card.cardId, "", e.target.value)
          }
        />
        {card.values.map((value) => (
          <ClassInputContainer
            key={value.id}
            value={value}
            cardId={card.cardId}
            onDeleteInput={onDeleteInput}
            onInputChange={onInputChange}
          />
        ))}
        <AppButton
          icon={<PlusIcon />}
          buttonType="text"
          className={styles.button}
          onClick={() => onAddInput(card.cardId)}
        >
          Добавить
        </AppButton>
      </div>
    </div>
  );
};
