import { appRouterPaths } from "../../../services/navigation/urls";
import { AssistantPage } from "../../../pages/AssistantPage";
import { AppRouteProps } from "../types";

export const assistantPageRoute: AppRouteProps = {
  path: appRouterPaths.assistant,
  element: <AssistantPage />,
  link: "/assitant",
  name: "Ассистент",
};
