import type { CardInputValues } from "./ClassLeftContainer";

export const classLeftInputValues: CardInputValues[] = [
  {
    cardId: String(Math.random()),
    variant: "",
    descValue: "",
    values: [
      {
        id: String(Math.random()),
        value: "",
      },
    ],
  },
  {
    cardId: String(Math.random()),
    variant: "",
    descValue: "",
    values: [
      {
        id: String(Math.random()),
        value: "",
      },
    ],
  },
  {
    cardId: String(Math.random()),
    variant: "",
    descValue: "",
    values: [
      {
        id: String(Math.random()),
        value: "",
      },
    ],
  },
];
