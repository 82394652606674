import type { AppIconProps } from "../IconTypes";

export const EyeIcon: React.FC<AppIconProps> = ({
  width,
  height,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width || "17"}
      height={height || "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2219 7.59688C13.7407 4.47656 11.5016 2.90625 8.50003 2.90625C5.4969 2.90625 3.2594 4.47656 1.77815 7.59844C1.71874 7.72425 1.68793 7.86165 1.68793 8.00078C1.68793 8.13991 1.71874 8.27732 1.77815 8.40312C3.2594 11.5234 5.49847 13.0938 8.50003 13.0938C11.5032 13.0938 13.7407 11.5234 15.2219 8.40156C15.3422 8.14844 15.3422 7.85469 15.2219 7.59688ZM8.50003 11.9688C5.97972 11.9688 4.1344 10.6906 2.83284 8C4.1344 5.30938 5.97972 4.03125 8.50003 4.03125C11.0203 4.03125 12.8657 5.30938 14.1672 8C12.8672 10.6906 11.0219 11.9688 8.50003 11.9688ZM8.43753 5.25C6.91878 5.25 5.68753 6.48125 5.68753 8C5.68753 9.51875 6.91878 10.75 8.43753 10.75C9.95628 10.75 11.1875 9.51875 11.1875 8C11.1875 6.48125 9.95628 5.25 8.43753 5.25ZM8.43753 9.75C7.47034 9.75 6.68753 8.96719 6.68753 8C6.68753 7.03281 7.47034 6.25 8.43753 6.25C9.40472 6.25 10.1875 7.03281 10.1875 8C10.1875 8.96719 9.40472 9.75 8.43753 9.75Z"
        fill="#FCFFFE"
      />
    </svg>
  );
};
